<template>
  <el-form ref="loginRef" :model="loginDatas" :rules="loginRules" label-width="120px" class="loginCompts"
    @submit.prevent>
    <el-form-item label-width="0px" prop="mobile">
      <el-input placeholder="手机号" type="text" prefix-icon="el-icon-mobile-phone" v-model="loginDatas.mobile"
        clearable />
    </el-form-item>
    <el-form-item label-width="0px" prop="password">
      <el-input placeholder="登陆密码" type="password" prefix-icon="el-icon-lock" v-model="loginDatas.password" />
    </el-form-item>
    <el-form-item label-width="0px" :error="setFilterOfContainer.loginDatas.vfyErrorMsg" prop="verifyCode">
      <div class="verificationCode">
        <el-input placeholder="验证码" type="text" v-model="loginDatas.verifyCode" />
        <div class="vrfCode">
          <div class="vrfCodeCtn" @click="reloadVerifyImage">
            <el-image style="width: 100px; height: 100px" :src="setFilterOfContainer.loginDatas.imageURL" fit="cover">
              <!-- 加载图片失败提示：无内容 -->
              <template #error>
                <div class="image-slot">点击加载图片</div>
              </template>
            </el-image>
          </div>
        </div>
      </div>
    </el-form-item>
    <el-form-item label-width="0px">
      <el-button type="success" @click="loginSubmitFunc" native-type="submit">登陆</el-button>
    </el-form-item>
    <el-form-item label-width="0px" class="activeitem">
      <span @click="forgetPwdFunc">忘记密码</span>
      <span @click="registSpan">注册新账号</span>
    </el-form-item>
  </el-form>
</template>
<script>
import { reactive, inject, ref } from 'vue';
import { useRouter } from 'vue-router';

import LoginClass from './class/loginClass'; // 登陆类
import SessionIsExpireClass from './class/sessionIsExpire'; // 验证码
// import Rules from '../../utils/utilsClass/rulesClass'; // form表单校验
import VerifyCodeClass from '../../utils/VerifyCodeClass';
import MenuListClass from '../../store/class/menuListClass';

export default {
  name: '#loginCompts',
  components: {},
  setup() {
    const router = useRouter();
    const loginRef = ref('');
    const setFilterOfContainer = reactive(inject('setFilterOfContainer'));
    const getCompRefFunc = inject('getCompRefFunc'); // 获取到当前展示页的Ref数据
    const storeDatas = reactive(inject('storeDatas'));
    const subMenuObj = reactive(inject('subMenusBus')); // 快捷菜单
    const vfyCLS = new VerifyCodeClass('login', setFilterOfContainer);
    // 登陆表单信息
    const loginDatas = reactive({
      mobile: '', // 手机
      password: '', // 密码
      verifyCode: '', // 验证码
    });
    const delayTime = 500;
    // 表单验证信息
    const loginRules = reactive({
      mobile: [
        {
          required: true,
          timeSetObj: null,
          asyncValidator: (rule, value) => new Promise((resolve, reject) => {
            clearTimeout(loginRules.mobile.timeSetObj);
            loginRules.mobile.timeSetObj = setTimeout(() => {
              if (value === '') {
                reject(new Error('必填内容'));
              } else if (value.length !== 11) {
                reject(new Error('请正确填写中国大陆地区手机号'));
              } else if (!/^[1][3458]+[0-9]{9}$/.test(value)) {
                reject(new Error('请正确填写中国大陆地区手机号'));
              } else {
                resolve(value);
              }
            }, delayTime);
          }),
          trigger: ['blur', 'change'],
        },
      ],
      password: [{
        required: true,
        timeSetObj: null,
        asyncValidator: (rule, value) => new Promise((resolve, reject) => {
          clearTimeout(loginRules.password.timeSetObj);
          loginRules.password.timeSetObj = setTimeout(() => {
            if (value === '') {
              reject(new Error('必填内容'));
            } else if (/[^A-Za-z0-9_&@.]+/.test(value)) {
              reject(new Error('请勿输入特殊字符'));
            } else if (!/^[\w&@.]{6,18}$/.test(value)) {
              reject(new Error('密码长度为6~18位组成'));
            } else if (/([\w&@.])\1{5}/.test(value)) {
              reject(new Error('请勿输入相同字符'));
            } else {
              resolve(value);
            }
          }, delayTime);
        }),
        trigger: ['blur', 'change'],
      }],
      verifyCode: [{
        required: true,
        timeSetObj: null,
        asyncValidator: (rule, value) => new Promise((resolve, reject) => {
          clearTimeout(loginRules.verifyCode.timeSetObj);
          loginRules.verifyCode.timeSetObj = setTimeout(() => {
            if (value === '') {
              reject(new Error('必填内容'));
            } else if (value.length !== 4) {
              reject(new Error('长度为4位'));
            } else {
              resolve(value);
            }
          }, delayTime);
        }),
        trigger: ['blur', 'change'],
      }],
    });
    // 点击登陆时调用的函数，在当前页来判断是否需要调用loadVerifyCode()
    setFilterOfContainer.loginRunLoadFunc = () => {
      const e = new SessionIsExpireClass('login', setFilterOfContainer);
      e.HandleLoadVerifyCode();
    };
    // 设置登陆图片信息
    setFilterOfContainer.setLoginImageDatas = (obj) => {
      if (Object.prototype.hasOwnProperty.call(obj, 'base64Img')) {
        setFilterOfContainer.loginDatas.imageURL = `data:image/jpg;base64,${obj.base64Img}`;
      }
    };
    // 清空原有登陆的值
    setFilterOfContainer.clearLoginOldFormValue = () => {
      loginDatas.mobile = '';
      loginDatas.password = '';
      loginDatas.verifyCode = '';
      loginRef.value.resetFields(); // 重置表单校验结果
    };

    // reloadVerifyImage重新加载验证图
    function reloadVerifyImage() {
      vfyCLS.loadVerifyCode(vfyCLS.CodeNewVfyCode).then((res) => {
        setFilterOfContainer.setLoginImageDatas(res);
      });
    }

    // registSpan 点击注册新账户
    function registSpan() {
      setFilterOfContainer.setCurrentModel('regist'); // 切换成"注册(regist)"
      const e = new SessionIsExpireClass('regist', setFilterOfContainer);

      e.HandleLoadVerifyCode();
    }

    // 登陆点击
    function loginSubmitFunc() {
      const { createCustomsDatasBus } = getCompRefFunc().value; // 有可能会undefined
      const loginInst = new LoginClass(loginDatas, '', createCustomsDatasBus, setFilterOfContainer, storeDatas, router);
      loginInst.refNode = loginRef.value;
      loginInst.SubimtFunc();
    }
    // forgetPwdFunc 忘记密码
    function forgetPwdFunc() {
      const menuListClass = new MenuListClass(storeDatas, setFilterOfContainer, router);
      menuListClass.hideLoginWindow(false);
      router.replace({ path: '/usersecurity' }).catch((err) => {
        console.error(err);
      }); // 跳转至账户安全
    }
    return {
      loginRef,
      loginDatas,
      loginRules,
      setFilterOfContainer,
      subMenuObj,
      getCompRefFunc,
      reloadVerifyImage,
      registSpan,
      loginSubmitFunc,
      forgetPwdFunc,
    };
  },
};
</script>
<style lang="scss" scoped>
:deep(.el-form-item) {
  margin-bottom: 10px !important;
  padding: 0;
  position: relative;
}

:deep(.el-form-item__error) {
  top: 83%;
}

:deep(.el-input__inner) {
  margin-bottom: 7px;
}

:deep(.el-button.el-button--success) {
  background: $loginbtncolor;
  margin-top: 20px;
  border-color: $loginbtncolor;
  width: 100%;
}

:deep(.el-input__inner) {
  height: none;
}

:deep(.el-input__icon) {
  color: rgba($buttomcolor, 0.8);
}

.verificationCode {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0;
  padding: 0;

  :deep(.el-input) {
    width: 68%;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  :deep(.el-input .el-input__inner) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .vrfCode {
    flex: 1;
    color: rgba($loginbtncolor, 0.8);
    text-align: center;
    vertical-align: middle;
    position: relative;

    .vrfCodeCtn {
      position: absolute;
      bottom: 0;
      width: 100%;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      cursor: pointer;
      line-height: 40px;
      height: 40px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid rgba(153, 153, 153, 0.6);
      border-left: none;
      margin-bottom: 7px;

      :deep(.el-image) {
        width: 113px !important;
        height: 38px !important;
      }
    }
  }
}

.activeitem {
  :deep(.el-form-item__content) {
    width: 100%;
    display: flex;
    justify-content: center;

    span {
      cursor: pointer;
    }

    span:nth-child(1) {
      margin-right: 20px;
      position: relative;
    }

    span:nth-child(1):after {
      content: '|';
      font-size: 20px;
      transform: scale(0.2, 1.1);
      position: absolute;
      right: -15px;
      top: -1px;
    }
  }
}
</style>
