import { ElMessage } from 'element-plus';
import TabClkClass from '../../components/tabslist/class/tabClkClass';
import myAxios from '../../utils/axios/index_v2';
import MenuListClass from './menuListClass'; // 菜单栏类

// QueryAuth 鉴权用户
export default class QueryAuthClass extends MenuListClass {
  constructor(storeDatas, setFilterOfContainer, router) {
    super(storeDatas, setFilterOfContainer, router);
    this.setFilterOfContainer = setFilterOfContainer;
    this.router = router;
    this.refreshEndTime = null;
    this.timeoutNum = null;
  }

  // queryAuthToken 向服务器校验TOKEN正确性
  queryAuthToken = async () => {
    await myAxios()
      .initAxios({
        method: 'GET',
        url: '/query_auth',
      })
      .then((res) => {
        // console.log('下次鉴权时间：', res.refreshEndTime, '登陆:', res);
        this.refreshEndTime = res.refreshEndTime || null;
        if (res.code === 200 && res.isLogin) {
          try {
            this.setElementInfo(res); // 成功登陆后设置元素信息
          } catch (error) {
            console.error(error);
          }
        }
      })
      .catch((err) => {
        // console.log('下次鉴权时间：', err.refreshEndTime, '未登陆：', err);
        this.refreshEndTime = err.refreshEndTime || null;
        // 访问频繁除外
        // 未登陆{code: 1003, message: '访问过于频繁,请x秒后再试！'}
        // 登陆 {code: 1003, message: '访问过于频繁,请x秒后再试！', username: '136****88'}
        if (err.code === 1003 && Object.prototype.hasOwnProperty.call(err, 'userNickname')) {
          try {
            this.setElementInfo(err); // 成功登陆后设置元素信息
          } catch (error) {
            console.error(error);
          }
        } else if (err.code === 1007) {
          ElMessage({
            type: 'error',
            message: err.message,
            duration: 3000,
          });
        } else {
          try {
            this.setElementInfo(err); // 成功登陆后设置元素信息
          } catch (error) {
            console.error(error);
          }
        }
      })
      .finally(() => {
        if (this.refreshEndTime) {
          // 定时刷新 (目的：为了更新后台数据过期时间)
          clearTimeout(this.timeoutNum);
          const endTime = new Date(this.refreshEndTime.substr(0, 19).replaceAll('-', '/')).getTime();
          const nowTime = new Date().getTime();
          const subTime = endTime - nowTime;
          this.timeoutNum = setTimeout(() => {
            this.queryAuthToken();
          }, subTime - Math.ceil(subTime / 10 - 500));
        }
        Object.assign(this.setFilterOfContainer, {
          loadCustomsdocsState: true, // 制作报关文件查询文件状态【fase：无需加载，true：需要重新加载】
          loadOnPayLineState: true, // 在线支付是否加载过数据【fase：无需加载，true：需要重新加载】
          loadHomeStatistics: true, // 主页中统计数据是否需要加载【fase：无需加载，true：需要重新加载】
        });
      });
  };

  /** logOutFunc 退出登陆
   *  1,退出清除登陆信息
   *  2,
   */
  logOutFunc = (tipmsg = '退出成功') => {
    if (this.setFilterOfContainer.isLogin === true) {
      this.setFilterOfContainer.clearRegistOldFormValue(); // 清除注册input信息

      // 退出所有tabs，并跳转至主页
      const tabClkClass = new TabClkClass(this.storeDatas, this.storeDatas.tabsDataBus['/'], this.router);
      tabClkClass.closeAllTabs();
      myAxios()
        .initAxios({
          method: 'POST',
          url: '/logout',
          data: {
            mobile: this.setFilterOfContainer.userNickname,
          },
        })
        .then((res) => {
          // 提示成功退出 或 用户自定义内容
          ElMessage({
            message: tipmsg,
            type: 'success',
          });
          this.setElementInfo(res); // 成功登陆后设置元素信息

          // 重置数据
          Object.assign(this.setFilterOfContainer, {
            loadCustomsdocsState: true, // 制作报关文件查询文件状态
            loadOnPayLineState: true, // 在线支付是否加载过数据
            loadHomeStatistics: true, // 主页中统计数据是否需要加载
          });

          if (Object.prototype.hasOwnProperty.call(this.setFilterOfContainer, 'clearHomeFunc')) {
            this.setFilterOfContainer.clearHomeFunc();// 清空的主页中的数据
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      ElMessage({
        message: '未登陆',
        type: 'success',
      });
    }
  };
}
