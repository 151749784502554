<template>
  <el-form ref="registRef" :model="registDatasForm" :rules="registRules" label-width="120px" class="registForm">
    <el-form-item label-width="0px" prop="mobile">
      <el-input placeholder="手机号" type="text" prefix-icon="el-icon-mobile-phone" v-model="registDatasForm.mobile"
        clearable />
    </el-form-item>
    <el-form-item label-width="0px" prop="password">
      <el-input placeholder="6~18位,字母、数字组成" type="password" prefix-icon="el-icon-lock"
        v-model="registDatasForm.password" :show-password="true" clearable />
    </el-form-item>
    <el-form-item label-width="0px" prop="mb_verify_code">
      <div class="mobileVerifyCode">
        <el-input placeholder="手机验证码" type="text" v-model="registDatasForm.mb_verify_code" />
        <div class="vrfCode">
          <div class="vrfCodeCtn getMobvrfCodeBase"
            @click.passive="mbverifyCodeclass.HandelGetMbFunc(registRef, registDatasForm.mobile)">{{ mbInputText }}</div>
        </div>
      </div>
      <!-- <template #error >
        <text class="el-form-item__error">{{mbErrorMsg}}</text>
      </template>-->
    </el-form-item>
    <el-form-item label-width="0px">
      <el-button type="success" @click="submitRegist">注册</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import {
  reactive, inject, toRefs, ref,
} from 'vue';
import { useRouter } from 'vue-router';
import MbverifyCode from './class/regist_getMbVerifyCode';
import RegistCls from './class/registClass';

export default {
  name: '#registForm',
  components: {},
  setup() {
    const registRef = ref('');
    const setFilterOfContainer = reactive(inject('setFilterOfContainer'));
    const getCompRefFunc = inject('getCompRefFunc'); // 获取到当前展示页的Ref数据
    const datas = setFilterOfContainer.registDatas;
    const router = useRouter();
    const storeDatas = inject('storeDatas');
    // 实例化一个类
    const mbverifyCodeclass = new MbverifyCode(setFilterOfContainer.registDatas, 60);
    const registDatasForm = reactive({
      // 登陆表单信息
      mobile: '', // 手机号
      password: '', // 密码
      mb_verify_code: '', // 手机验证码
    });

    const delayTime = 500;// 延时校验
    const registRules = reactive({
      mobile: [
        {
          required: true,
          timeSetObj: null,
          asyncValidator: (rule, value) => new Promise((resolve, reject) => {
            clearTimeout(registRules.mobile.timeSetObj);
            registRules.mobile.timeSetObj = setTimeout(() => {
              if (value === '') {
                reject(new Error('必填内容'));
              } else if (value.length !== 11) {
                reject(new Error('请正确填写中国大陆地区手机号'));
              } else if (!/^[1][3458]+[0-9]{9}$/.test(value)) {
                reject(new Error('请正确填写中国大陆地区手机号'));
              } else {
                resolve(value);
              }
            }, delayTime);
          }),
          trigger: ['blur', 'change'],
        },
      ],
      password: [
        {
          required: true,
          timeSetObj: null,
          asyncValidator: (rule, value) => new Promise((resolve, reject) => {
            clearTimeout(registRules.password.timeSetObj);
            registRules.password.timeSetObj = setTimeout(() => {
              if (value === '') {
                reject(new Error('必填内容'));
              } else if (/[^A-Za-z0-9_&@.]+/.test(value)) {
                reject(new Error('请勿输入特殊字符'));
              } else if (!/^[\w&@.]{6,18}$/.test(value)) {
                reject(new Error('密码长度为6~18位组成'));
              } else if (/([\w&@.])\1{5}/.test(value)) {
                reject(new Error('请勿输入相同字符'));
              } else {
                resolve(value);
              }
            }, delayTime);
          }),
          trigger: ['blur', 'change'],
        },
      ],
      mb_verify_code: [
        {
          required: true,
          timeSetObj: null,
          asyncValidator: (rule, value) => new Promise((resolve, reject) => {
            clearTimeout(registRules.mb_verify_code.timeSetObj);
            registRules.mb_verify_code.timeSetObj = setTimeout(() => {
              if (value === '') {
                reject(new Error('必填内容'));
              } else if (value.length !== 4) {
                reject(new Error('长度为4位'));
              } else {
                resolve(value);
              }
            }, delayTime);
          }),
          trigger: ['blur', 'change'],
        },
      ],
    });
    // 添加清空当前form值
    setFilterOfContainer.clearRegistOldFormValue = () => {
      mbverifyCodeclass.clearInterValFunc(); // 清除倒计时
      registDatasForm.mobile = '';
      registDatasForm.password = '';
      registDatasForm.mb_verify_code = '';
      registRef.value.resetFields(); // 重置表单校验结果
    };

    const myMethods = reactive({
      // getMbVerifyCodeFunc 获取手机验证码
      getMbVerifyCodeFunc: () => {
        mbverifyCodeclass.HandelGetMbFunc(registRef.value, registDatasForm.mobile);
      },

      // submitRegist 提交注册方法
      submitRegist: () => {
        const { createCustomsDatasBus } = getCompRefFunc().value; // 有可能会undefined
        // 先判断数据是否按要求填写
        const R = new RegistCls('regist', registRef.value, registDatasForm, createCustomsDatasBus, setFilterOfContainer, storeDatas, router);
        R.SubMitFunc(mbverifyCodeclass.getTimeoutNode()); // 提交注册至服务器
      },
    });
    return {
      mbverifyCodeclass,
      ...toRefs(myMethods),
      ...toRefs(datas),
      registRef,
      registDatasForm,
      registRules,
      setFilterOfContainer,
      // getCompRefFunc,
    };
  },
};
</script>
<style lang="scss" scoped>
:deep(.el-form-item) {
  margin-bottom: 10px !important;
  padding: 0;
  position: relative;
}

:deep(.el-form-item__error) {
  top: 83%;
}

:deep(.el-input__inner) {
  margin-bottom: 7px;
}

:deep(.el-button.el-button--success) {
  background: $loginbtncolor;
  margin-top: 20px;
  border-color: $loginbtncolor;
  width: 100%;
}

:deep(.el-input__inner) {
  height: none;
}

:deep(.el-input__icon) {
  color: rgba($buttomcolor, 0.8);
}

.verificationCode,
.mobileVerifyCode {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0;
  padding: 0;

  :deep(.el-input) {
    width: 68%;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  :deep(.el-input .el-input__inner) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .vrfCode {
    flex: 1;
    color: rgba($loginbtncolor, 0.8);
    text-align: center;
    vertical-align: middle;
    position: relative;

    .vrfCodeCtn {
      position: absolute;
      bottom: 0;
      width: 100%;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      line-height: 40px;
      height: 40px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid rgba(153, 153, 153, 0.6);
      margin-bottom: 7px;
      border-left: none;

      :deep(.el-image) {
        width: 113px !important;
        height: 38px !important;
      }
    }
  }
}

.getImgvrfCode {
  cursor: pointer;
}

.getMobvrfCodeDisabled {
  background-color: var(--el-disabled-fill-base);
  border: 1px solid var(--el-disabled-border-base) !important;
  border-left: none !important;
  // color: var(--el-disabled-color-base);
  color: black;
}

.getMobvrfCodeBase {
  background-color: white;
  cursor: pointer;
  color: rgba($loginbtncolor, 0.8);
}

// .err_content {
// 	color: red;
//   // background: white;
// 	position: absolute;
// 	top: 3px;
// 	left: 2px;
//   margin-left: 4%;
// 	width: 63%;
// 	height: 75%;
// }</style>
