export default class TabClkClass {
  #router

  /**
   *
   * @param {object} storeDatas 共享存储数据
   * @param {string} tabDatas 操作tabsDataBus中的tab节点
   * @param {api} router 路由
   */
  constructor(storeDatas, tabDatas, router) {
    this.storeDatas = storeDatas;
    this.tabDatas = tabDatas;//  操作的当前tab数据
    this.#router = router;
  }

  // deleteTabsData 删除tab前修改tabsbus中的值
  // 原理：点击了tab能将数据传递至this.tabDatas中，来删除 this.storeDatas.tabsDataBus中的数据
  deleteTabsData=() => {
    try {
      // 判断当前是否在显示元素
      let prevKey = '';
      // 删除值当前为显示状态
      if (this.storeDatas.tabsDataBus[this.tabDatas.navigatTo].active === 1) {
        // 当前元素在显示当中，删除时需将前一个状态active置为1
        Object.keys(this.storeDatas.tabsDataBus).forEach((e) => {
          if (e === this.tabDatas.navigatTo) {
            this.storeDatas.tabsDataBus[prevKey].active = 1;// 将上一个元素置为展示状态
            this.storeDatas.currentShowPageBus.name = prevKey;// 设置要跳转的路由页
          }
          prevKey = e;
        });
      } else {
        // 删除的不是展示状态，显示active为1的路由
        Object.values(this.storeDatas.tabsDataBus).forEach((e) => {
          if (e.active === 1) {
            this.storeDatas.currentShowPageBus.name = e.navigatTo;// 设置要跳转的路由页
          }
        });
      }
      // 删除当前
      delete (this.storeDatas.tabsDataBus[this.tabDatas.navigatTo]);
    } catch (error) {
      console.error(error);
    }
  }

  /** closeTabFunc 关闭单个tab事件总线
   * @param {*} navigatToURL 要跳转的URL地址(默认向上一tab跳转)
   * @param {*} needNavigat  判断是否需要跳转（默认要跳转）
   * navigatToURL 指定跳转路径，为空时向上取tab值，有值则取指定值（例如：'/customsdocs'）
  */
  closeTabFunc=(needNavigat = true, navigatToURL = '') => {
    try {
      this.deleteTabsData();
      // 删除keepAliveList中的值，清除缓存
      const lowPath = this.tabDatas.navigatTo.replace('/', '').toLowerCase();

      // 判断是否存在
      const findIndex = this.storeDatas.keepAliveLists.indexOf(lowPath);
      if (findIndex !== -1) { // 存在，即删除当前缓存标签
        this.storeDatas.keepAliveLists.splice(findIndex, 1);
      } else {
        return;
      }
      this.tabDatas.query = {};// 清空query参数
      navigatToURL = navigatToURL || this.storeDatas.currentShowPageBus.name;// 取指定值或向上取值
      // 执行跳转
      if (needNavigat) {
        this.#router.replace({ // 禁止返回
          path: navigatToURL, // 路径
          query: this.storeDatas.subMenusBus[navigatToURL].query, // 参数
        }).catch((err) => {
          console.error(err);
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  // closeAllTabs 关闭全部tabs
  closeAllTabs=() => {
    this.#router.replace({ path: '/' }).catch((err) => {
      console.error(err);
    });
    this.storeDatas.currentShowPageBus.name = '/';// 需要设置

    // 清除缓存
    Object.keys(this.storeDatas.keepAliveLists).forEach((key) => {
      delete this.storeDatas.keepAliveLists[key];
    });

    // 删除tabs所页面tab只保存"/"主页
    Object.keys(this.storeDatas.tabsDataBus).forEach((key) => {
      if (key !== '/') delete this.storeDatas.tabsDataBus[key];
    });
  }

  // 点击tab栏跳转事件
  tabClkFunc=() => {
    try {
      this.#router.push({
        path: this.tabDatas.navigatTo,
        query: { ...this.tabDatas.query },
      }).catch((err) => {
        console.error(err);
      });
      const navigatToName = this.tabDatas.navigatTo;
      this.storeDatas.tabsDataBus[navigatToName] = this.tabDatas;
      this.storeDatas.currentShowPageBus.name = this.tabDatas.navigatTo;
      // 添加actice状态标识
      Object.values(this.storeDatas.tabsDataBus).forEach((e) => {
        const keyStr = e.navigatTo;
        if (keyStr === navigatToName) {
          this.storeDatas.tabsDataBus[keyStr].active = 1;
        } else {
          this.storeDatas.tabsDataBus[keyStr].active = 0;
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
}
