import { createApp } from 'vue';
import * as ElIcons from '@element-plus/icons';
import App from './App.vue';
import router from './router';
// import store from './store';
import ChangeIcon from './utils/changeIcon';
import ElementPlus from '../node_modules/element-plus';

import zhCn from '../node_modules/element-plus/es/locale/lang/zh-cn';

const app = createApp(App);
// 注册element Icons图标
Object.entries(ElIcons).forEach((itemAry) => {
  app.component(itemAry[0], itemAry[1]);
});
app.use(router).use(ElementPlus, { locale: zhCn }).use(ChangeIcon).mount('#app');
// app.config.globalProperties.store = app;
// Aim {name: 'Aim', __file: 'packages/components/Aim.vue'}
