import {
  isRef,
  nextTick,
} from 'vue';

export default class CreateCstmUtilClass {
  constructor(createCustomsDatasBus, setFilterOfContainer) {
    this.createCustomsDatasBus = createCustomsDatasBus; // 所有子组件数据
    this.setFilterOfContainer = setFilterOfContainer;// 登陆共享数据
  }

  // deepCopyDatas 深拷贝
  deepCopyDatas = (oldDatas, newDatas) => {
    Object.keys(oldDatas).forEach((key) => {
      if (key === 'loginedFunc') {
        newDatas[key] = oldDatas[key].toString();
      }
      // 不取$ref的值，是循环索引，内存会溢出
      if (/Ref$/.test(key) || typeof oldDatas[key] === 'function') return;

      if ((typeof oldDatas[key] === 'object') && (oldDatas[key] !== null)) {
        // 对象类型
        const valDatas = isRef(oldDatas[key]) ? oldDatas[key].value : oldDatas[key];
        // 判断是否为ref响应式值,直接取值
        if (isRef(oldDatas[key]) && (typeof valDatas !== 'object')) { // 为ref类型且值非数组或对象
          newDatas[key] = oldDatas[key].value;
        } else { // 非ref的（数组，对象，函数）
          newDatas[key] = Array.isArray(valDatas) ? [] : {};// 设置对象或数据初始化
          this.deepCopyDatas(valDatas, newDatas[key]);
        }
      } else {
        // 其他类型
        newDatas[key] = isRef(oldDatas[key]) ? oldDatas[key].value : oldDatas[key];
      }
    });
  }

  // deepAssignData 响应式赋值
  deepAssignData = (oldDatas, newDatas) => {
    Object.keys(newDatas).forEach((key) => {
      if (key === 'loginedFunc') {
        oldDatas[key] = new Function(`return ${newDatas[key]}`)();
        return;
      }
      const typeKey = typeof newDatas[key];
      if (isRef(oldDatas[key]) && typeKey === 'string') {
        // 先以原数据作为判断依据是否为响应式数据
        oldDatas[key].value = newDatas[key];
      } else if (isRef(oldDatas[key]) && ((typeKey === 'object') && (newDatas[key] !== null))) {
        // 响应式对象
        if (oldDatas[key] === null || oldDatas[key] === undefined) {
          oldDatas[key].value = Array.isArray(newDatas[key]) ? [] : {};
        }
        this.deepAssignData(oldDatas[key], newDatas[key]);
      } else if ((typeKey === 'object') && (newDatas[key] !== null)) {
        // 对象
        if (key === 'stepAllDatas') {
          oldDatas[key].stepDatas = [];
        }
        if (isRef(oldDatas[key])) { // 为ref类型且值非数组或对象
          // 响应式
          oldDatas[key].value = newDatas[key];
        } else {
          // 其他，非ref的（数组，对象，函数）
          if (oldDatas[key] === null || oldDatas[key] === undefined) {
            oldDatas[key] = Array.isArray(newDatas[key]) ? [] : {};
          }
          this.deepAssignData(oldDatas[key], newDatas[key]);
        }
      } else {
        oldDatas[key] = newDatas[key];
      }
    });
  }

  // initDetailTableStyle 初始化"产品信息栏"表单table样式
  initDetailTableStyle = (modelType = 'getDataByUuid') => {
    const {
      hsTabaleRef, weightFormRef, initDisabledStateFunc, setUnitOptionsFunc, rowClkBus, tableDatas,
      config, datas,
    } = this.createCustomsDatasBus;
    if (modelType === 'getDataByUuid') {
      // 设置高亮
      if (!(['editStateSave', 'editStateAdd', 'mixinEdite', 'mixinSave', 'mixinInit'].includes(rowClkBus.currentBtnState))) {
        rowClkBus.setCurrent(tableDatas[rowClkBus.rangeClkId || 0]); // 表格要展示高亮样式
      }

      initDisabledStateFunc();// 设置输入框可编辑状态
      setUnitOptionsFunc();// 设置法定单位值

      if (!this.setFilterOfContainer.isLogin) { // 设置登陆func
        this.setFilterOfContainer.loginedFunc = this.createCustomsDatasBus.loginedFunc;
      }

      nextTick(() => {
        // 定位至最新行
        hsTabaleRef.value.hsDetailTableRef.$refs.bodyWrapper.scrollTop = rowClkBus.rangeClkId * 34 - 2 * 34;
        // 清除错误提示
        weightFormRef.value.clearValidate();
      });
    } else if (modelType === 'getTradeByUuid') {
      // 获取提交的内容,不可编辑的展示及高亮第一行数据，
      Object.assign(datas.detailsDatas, tableDatas[0]);
      config.currentBtnState = 'tableStateShow';
      config.buttonState = false;
      config.disabledForm = true;
      rowClkBus.setCurrent(tableDatas[0]);
      initDisabledStateFunc();// 设置输入框可编辑状态
    }
  }

  // 判断用户是否登陆
  // @returns :true:已登陆,false:未登陆
  isLogined() {
    if (!this.setFilterOfContainer.isLogin) { // 未登陆，要求用户登陆
      this.setFilterOfContainer.setFilter(0.5);
      this.setFilterOfContainer.loginRunLoadFunc(); // 登陆将显示登陆弹窗
      return false;
    }
    return true;
  }
}
