// 左侧边栏的缩放
export default class ChangeSideBarClass {
  // 默认大图标
  constructor(sideBarObj) {
    this.sideBarObj = sideBarObj;
  }

  // 设置为大图标
  #smallSideBarModule = () => {
    Object.assign(this.sideBarObj, {
      widthVal: '200px', // 侧边栏宽度
      isShowNavList: 'img-navlogo_70', // 图片
      zoomBtnToptitle: '放大', // 提示文字，延时再更改
      isCollapse: false, // 侧边栏图标：小图标true，大图标false
      navLogo: 'bigLogo', // Logo图标bigLogo|smallLogo
    });
    // 延时处理提示文字
    setTimeout(() => {
      this.sideBarObj.zoomBtnToptitle = '缩小';
    }, 800);
  }

  // 设置为小图标
  #bigSideBarModule = () => {
    Object.assign(this.sideBarObj, {
      widthVal: '70px', // 侧边栏宽度
      isShowNavList: 'img-navlogo_200', // 图片
      zoomBtnToptitle: '缩小 ', // 提示文字，延时再更改
      isCollapse: true, // 侧边栏图标：小图标true，大图标false
      navLogo: 'smallLogo', // Logo图标bigLogo|smallLogo
    });
    // 延时处理提示文字
    setTimeout(() => {
      this.sideBarObj.zoomBtnToptitle = '放大';
    }, 800);
  }

  // setLocalSotrageOfSideWidth设置localstorage的宽度值，便于下次调用的宽度
  #setLocalSotrageOfSideWidth = () => {
    sessionStorage.setItem('navWidth', this.sideBarObj.widthVal);
  }

  // toggleSideBar 切换当前侧边栏数据
  toggleSideBar = () => {
    if (this.sideBarObj.widthVal === '70px') {
      this.#smallSideBarModule();
    } else {
      this.#bigSideBarModule();
    }
    this.#setLocalSotrageOfSideWidth();// 存储侧边栏宽度
  }

  // reLoadPageSetSideBarWidth重新加载页后初始宽度
  reLoadPageSetSideBarWidth() {
    const v = sessionStorage.getItem('navWidth');
    if (v !== null) { // 有值
      if (v === '70px') { // 与toggleSideBar相反
        this.#bigSideBarModule();
      } else {
        this.#smallSideBarModule();
      }
    }
  }
}
