/**
 * 两部分主成：网页所需权限 、用户类型
 * 根据登陆用户与访问页面所需权限，来判断当前用户是否可以访问此页，从而添加至白名单及菜单栏表
 */
export default class ConstClass {
    // 网页所需权限
    static PAGE_UNLOGIN_USER = 1; // 0001 未登陆用户访问

    static PAGE_LOGIN_USER = 3; // 0011 登陆用户访问

    static PAGE_ONLY_UNLOGIN_USER = 9; // 1001 只允许未登陆用户访问

    static PAGE_ADMIN = 15; // 1111超级管理员（允许查看审核页）

    // 用户类型
    static USER_LOGIN = 3; // 0011 登陆用户访问

    static USER_ROOT = 7; // 0111 管理用户访问

    static USER_ADMIN = 15; // 1111 超级管理员访问

    static USER_UNLOGIN = 9; // 1001 未登陆用户访问
}
