import { ElNotification } from 'element-plus';
import myAxios from './axios/index_v1';

/**
 * @parms {string} caller 调用者  'regist','login'
 * @parms {Object} setFilterOfContainer loginProvide.js 中的数据
 */

export default class VerifyCodeClass {
  constructor(caller, setFilterOfContainer) {
    this.nodeData = setFilterOfContainer;
    this.caller = caller;
    this.CodeNewVfyCode = 0;// 创建验证码
    this.CodeCheckVrfCode = 1;// 校验验证码
  }

  // 解析sessionStorage中的数据
  parseSessionStorageToOBJ = () => {
    const status = sessionStorage.getItem('verifyStatus');
    if (status === null || status === '') {
      return {};
    }
    const Obj = JSON.parse(window.atob(status));
    return Obj;
  }

  // FocusNode定位至错误输入框
  FocusNode = (nodeRef, num) => {
    const nodeEl = nodeRef.$el[num];
    nodeEl.focus();// 获得焦点
    nodeEl.select();// 全选
  }

  // UpdateSessionSotrage 更新session图片有效期更新
  UpdateSessionSotrage = (caller, lastStamp) => {
    // 设置超访状态值或初始值
    const verifyStatus = sessionStorage.getItem('verifyStatus');
    const newOBJ = {};
    newOBJ[caller] = lastStamp;

    if (verifyStatus === null || verifyStatus === '') {
      // 没有存储
      const tempCryptoStr = window.btoa(JSON.stringify(newOBJ));
      sessionStorage.setItem('verifyStatus', tempCryptoStr);
    } else {
      // 有存储
      const tempObj = JSON.parse(window.atob(verifyStatus)); // 解密成对象
      tempObj[caller] = lastStamp;
      const toStr = window.btoa(JSON.stringify(Object.assign(tempObj, newOBJ))); // 添加或修改对象再加密
      sessionStorage.setItem('verifyStatus', toStr);
    }
  }

  /** loadVerifyCode 获取验证码
       * @parm {int} modelType [NewVfyCode，ReloadVrfCode，CheckVrfCode]对应索引
       * @return 返回base64 image or error
       * 收到返回值：{code:200,results{base64img:xxxx,lastStamp:12034934}}
       * 说明：三种状态 timestamp(过期时间 int64) ,null(未加载),initstamp(初始加载string)
       * 将三种状态放置storage中存放
       */
  loadVerifyCode = (modelTypeInt) => new Promise((resolve) => {
    // 加载验证图片加载事件T
    this.caller = this.caller || 'login';// 默认值
    const data = {
      caller: this.caller,
      model_type: modelTypeInt,
    };
    myAxios()
      .initAxios({
        method: 'POST',
        url: '/verifycode',
        Headers: {
          Accept: 'application/json; charset=utf-8',
        },
        data,
      })
      .then((res) => {
        // 验证图片加载成功
        if (res.code === 200 && Object.prototype.hasOwnProperty.call(res, 'results')) {
          if (Object.prototype.hasOwnProperty.call(res.results, 'lastStamp')) {
            // 服务器返回lastStamp为"initstamp"则无需验证码并隐藏验证码输入框，反之显示隐藏框
            this.UpdateSessionSotrage(this.caller, res.results.lastStamp);
          }
          resolve(res.results);
        }
      })
      .catch((err) => {
        if (err.code !== 1003) { // 不是超限访问，前面提示过了
          ElNotification({
            title: '警示',
            message: '验证图加载失败',
            type: 'error',
          });
        }
      });
  })
}
