<template>
  <div class="LoginMain" id="LoginMain">
    <div class="mask" @click="maskFunc"></div>
    <el-row>
      <el-col :span="24">
        <el-tabs
          class="dialogBox"
          v-model="setFilterOfContainer.currentTabName"
          @tab-click="tabClickFunc"
        >
          <el-tab-pane label="登陆" name="login">
            <LoginComponent />
          </el-tab-pane>
          <el-tab-pane label="注册" name="regist">
            <RegitComponent />
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { reactive, toRefs, inject } from 'vue';
import LoginComponent from '../components/loginDialog/logincompts.vue';
import RegitComponent from '../components/loginDialog/regist.vue';
import SessionIsExpireClass from '../components/loginDialog/class/sessionIsExpire';

export default {
  name: '#LoginMain',
  components: {
    LoginComponent,
    RegitComponent,
  },
  setup() {
    const setFilterOfContainer = reactive(inject('setFilterOfContainer')); // 设置app模糊背景

    const datas = reactive({
      prevTabsName: null, // 记录上次点击的tabItem名称
    });

    setFilterOfContainer.setCurrentModel = (modelname) => {
      setFilterOfContainer.currentTabName = modelname || 'login'; // 默认login
    };
    const methods = reactive({
      // 遮罩层事件
      maskFunc: () => {
        setFilterOfContainer.clearLoginOldFormValue(); // 清空原有登陆的值
        setFilterOfContainer.clearRegistOldFormValue(); // 清空原有注册的值
        setFilterOfContainer.currentTabName = 'login';
        setFilterOfContainer.clearFilter(); // 清除模糊
      },
      tabClickFunc: (e) => {
        // 判断当前是否有paneName属性，获取当前点击的值
        if (
          Object.prototype.hasOwnProperty.call(e, 'paneName')
          && datas.prevTabsName !== e.paneName
        ) {
          const currentTabItem = e.paneName; // 当前点击tabsName
          if (currentTabItem === 'login') {
            // 只有login才加载验证码
            const isExpire = new SessionIsExpireClass(currentTabItem, setFilterOfContainer);
            isExpire.HandleLoadVerifyCode();
          }
          datas.prevTabsName = currentTabItem;
        }
      },
    });
    return {
      ...toRefs(datas),
      ...toRefs(methods),
      setFilterOfContainer,
    };
  },
};
</script>
<style lang="scss" scoped>
$redis: 8px;
$bgColor: rgb(216, 216, 216);
$borderColor: rgb(175, 175, 175);
$tabsBodyH: 280px; // 弹窗总高度
$tabsBodyW: 400px; // 弹窗总宽度
$tabsNavHeight: 40px; //导航栏高度
$navMargin: 15px; //导航栏下margin

.LoginMain {
  width: 100vw;
  min-width: 960px !important;
  min-height: 500px !important;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  .mask {
    position: absolute;
    background: rgba($color: black, $alpha: 0.5);
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .dialogBox {
    width: $tabsBodyW;
    min-height: $tabsBodyH;
    background: white;
    border-radius: $redis;
  }
}
:deep(.el-tabs__header.is-top) {
  margin: 0 0 $navMargin;
}
:deep(.el-tabs__nav.is-top) {
  // tabs's title
  width: 100%;
  height: $tabsNavHeight;
  line-height: $tabsNavHeight;
  padding: 0;
  margin: 0;
  border: 0px;
  border-radius: $redis $redis 0 0;
  background-color: $bgColor;
}
:deep(.el-tabs__item.is-top) {
  // tabs's item
  width: 50%;
  text-align: center;
  font-size: 16px;
  line-height: $tabsNavHeight;
  border-radius: $redis $redis 0 0;
  padding: 0;
  color: $borderColor;
}
:deep(.el-tabs__active-bar.is-top) {
  // tabs's active Bar
  width: 50%;
  height: 100%;
  background-color: white;
  z-index: 0;
  border-radius: $redis $redis 0 0;
}
:deep(.el-tabs__item.is-active) {
  // tabs's active style
  height: $tabsNavHeight;
  z-index: 1;
  color: $loginbtncolor;
}
:deep(.el-tabs__content) {
  width: 100%;
  // height: calc(#{$tabsBodyH} - #{$tabsNavHeight} - #{$navMargin});
  height: 100%;
  background: white;
  box-sizing: border-box;
  border-radius: $redis;
  border: 20px solid white;
  border-top: 10px solid white;
}
</style>
