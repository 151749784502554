import {
  ElMessageBox, ElNotification, ElLoading, ElMessage,
} from 'element-plus';
import md5 from 'blueimp-md5';
import myAxios from '../../../utils/axios/index_v2';
import MenuListClass from '../../../store/class/menuListClass';
import CreateCustomsClass from '../../createcustoms/class/createCustomsClass';

export default class RegistCls {
  #router

  constructor(caller, nodeRef, formDatas, createCustomsDatasBus, setFilterOfContainer, storeDatas, router) {
    this.caller = caller;// 调用者
    this.nodeRef = nodeRef;// ref节点
    this.formDatas = formDatas;// proty数据
    this.eload = null;// 加载句柄
    this.createCustomsDatasBus = createCustomsDatasBus;// 创建产品时共享数据
    this.setFilterOfContainer = setFilterOfContainer;// 共享数据
    this.storeDatas = storeDatas;
    this.#router = router;
  }

  /**
   *判断所有验证是否正确
   * @returns bool 有错true,无错false
   */
  verifyNode = async () => {
    let b = false;
    await this.nodeRef.validateField(Object.keys(this.formDatas), (err) => {
      if (err !== '') { // 错误时不为空
        b = true;
      }
    });
    return b;
  }

  // FocusNode定位至错误输入框
  FocusNode = (num) => {
    const nodeEl = this.nodeRef.$el[num];
    nodeEl.focus();// 获得焦点
    nodeEl.select();// 全选
  }

  // loadingfunc 加载提示
  loadingfunc = (title) => {
    this.eload = ElLoading.service({
      lock: true,
      text: title,
      background: 'rgba(0, 0, 0, 0.5)',
    });
  }

  // closeDialogFunc 关闭所有弹窗
  closeDialogFunc = () => {
    this.setFilterOfContainer.clearFilter();// 是否显示整个登陆弹窗 false:隐藏 true：显示
    this.setFilterOfContainer.clearLoginOldFormValue();// 清除登陆input信息
    this.setFilterOfContainer.clearRegistOldFormValue();// 清除注册input信息
  }

  // notification 提示信息（大框）
  notification = (title, message, type) => {
    ElNotification({
      title,
      message,
      type,
    });
  }

  // bitTransformInt将二进制转换为int
  bitTransformInt = (bitString) => parseInt(bitString, 2)

  /**
     * 提交注册
     */
  SubMitFunc = (setTimeoutNode = null) => {
    const that = this;
    const b = this.verifyNode();// 校验提交数据正确性
    b.then((isCorrect) => { // 所有输入框 true 验证失败，false 验证成功
      if (!isCorrect) { // 验证无错
        if (setTimeoutNode === null) {
          that.notification('错误提示', '验证码已过期，请重新获取！', 'error');
          return;
        }
        that.loadingfunc('正在注册');
        myAxios().initAxios({
          method: 'POST',
          url: '/register',
          Headers: { Accept: 'application/json; charset=utf-8' },
          data: {
            mobile: that.formDatas.mobile,
            password: md5(that.formDatas.password),
            mb_verify_code: that.formDatas.mb_verify_code,
          },
        }).then((res) => {
          // 关闭注册对话框
          that.eload.close();
          if (res.code === 200 && res.message === '注册成功') {
            const menuListClass = new MenuListClass(that.storeDatas, that.setFilterOfContainer, that.#router);
            menuListClass.hideWindowOfLoginSuccess(res);

            ElMessage({
              message: '注册成功',
              type: 'success',
            });
            that.registedOptions();
          }// 登陆成功
        }).catch((err) => {
          // {code: 4004, message: '手机验证码不正确'}
          that.eload.close();
          if (err.code !== 1003) { // 不是超限访问，前面提示过了
            ElMessageBox.alert(`${err.message}`, '警告', {
              confirmButtonText: 'OK',
              callback() {
                that.FocusNode(2);
              },
            });
          }
        });
      }
    });
  }

  // registedOptions 注册成功后要操作事务
  registedOptions = () => {
    switch (this.#router.currentRoute.value.path) {
      case '/createcustoms':
        if (this.createCustomsDatasBus !== undefined) {
          const createCustomsClass = new CreateCustomsClass(this.createCustomsDatasBus, this.setFilterOfContainer, this.storeDatas, this.#router);
          // 先将redis中的数据写入sql中
          createCustomsClass.handelName = createCustomsClass.setDataByUuid;
          createCustomsClass.axiosFunc();
          // 重新向后台获取数据
          createCustomsClass.queryDataFromRouter();
        }
        break;
      case '/accountingdetail':// 账务明细
        // 登陆成功后执行加载已支付订单,其实注册是没有支付数据，可以无需加载
        // this.setFilterOfContainer.accountingDataFunc();
        break;
      case '/':// 主页
        this.setFilterOfContainer.loadHomeFunc();
        break;

      default:
        break;
    }
  }
}
