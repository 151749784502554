// 根据深色模式来更改当前icon图标

export default function () {
  return new Promise(() => {
    const runFunc = ((d) => {
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      // link.type = 'image/x-icon';
      link.rel = 'icon';
      if (d.matches) {
        link.href = '/favicon_light80.ico';
      } else {
        link.href = '/favicon_dark80.ico';
      }
      document.getElementsByTagName('head')[0].appendChild(link);
    });
    // 深色模式，监听到深色模式再做回调重新创建
    const f = window.matchMedia('(prefers-color-scheme: dark)');
    runFunc(f);
    f.addListener((e) => {
      runFunc(e);
    });
  });
}
// css判断是否是深色,标注
/**
//  *@media (prefers-color-scheme: dark) {
// 	.test {
// 		color: red;
// 	}
// }
// @media (prefers-color-scheme: light) {
// 	.test {
// 		color: green;
// 	}
// }
 */
