/** 判断当前是否过期
   *
   * @param {Object} setFilterOfContainer app设置的组件共享方法组
   * @param {String} caller 调用者 'regist'、'login'
   * 思路：
   * 1，点击登陆时，弹出登陆对话框
   * 2，判断localstorage 中verifyStatus是否有当前caller中的值
   * 3，verifyStatus对象的值分为：initstamp(初始值，没加过),到期时间戳,将此对象window.atob加密保存
   * 4，每次加载时先判断下验证码是否过期，或是否需要提示重新加载。
   * 5，安全：当用记恶意向服务器提交数据时，则需要提示验证码
   *
*/

import VerifyCodeClass from '../../../utils/VerifyCodeClass';

export default class SessionIsExpireClass extends VerifyCodeClass {
  #datas// 当前调用者的datas信息

  // #sessionStorage// 存储当前存放的值

  constructor(caller, setFilterOfContainer) {
    super(caller, setFilterOfContainer);
    this.setFilterOfContainer = setFilterOfContainer;
    this.caller = caller;
    this.#datas = this.setFilterOfContainer[`${this.caller}Datas`];
  }

  // 加载验证码
  LoadVerifyCode() {
    this.loadVerifyCode(this.CodeNewVfyCode).then((res) => {
      if (Object.prototype.hasOwnProperty.call(res, 'base64Img')) {
        if (res.base64Img === '') {
          this.#datas.imageURL = '';
        } else {
          // 有值 则展示验证图
          this.#datas.imageURL = `data:image/jpg;base64,${res.base64Img}`;
        }
      }
    });
  }

  // 根据sessionStroage存储的时间是否过期来判断是否 加载验证码
  HandleLoadVerifyCode() {
    const obj = this.parseSessionStorageToOBJ();
    const nowTime = new Date().valueOf();
    // 只要有图且未过期不需要加载，其他都要重新加载验证码
    if (obj[this.caller] <= nowTime || this.#datas.imageURL === '') {
      this.LoadVerifyCode();
    }
  }
}
