// import stepStatusAry from './stepStatusAry';

const del = { title: '删除', funcName: 'deleteFunc', tooltip: '删除本条内容' };
const edit = { title: '编辑', funcName: 'editFunc', tooltip: '编辑数据' };
const detail = { title: '详情', funcName: 'detailFunc', tooltip: '查看报关资料详情' };
const pay = { title: '支付', funcName: 'payFunc', tooltip: '扫码支付' };
const signature = { title: '委托授权', funcName: 'signFun', tooltip: '授权电子报关委托' };
const download = { title: '下载', funcName: 'downLoadFunc', tooltip: '下载报关资料' };
const copy = { title: '复制', funcName: 'copyFunc', tooltip: '创建一组与之相同数据' };

/*
依据当前用户状态值，返回当前用户可以进行的操作按钮
*/
// 登陆
const powerOptions = {
  1: [edit, del], // 待提交
  2: [detail, edit, del], // 审核中
  3: [detail, edit, del], // 审核不通过
  4: [detail, edit, pay, copy, del], // 审核通过
  5: [detail, download, signature, copy], // 待委托授权
  6: [detail, download, copy], // 已支付
  15: [detail, edit, download, copy, signature, del], // 超管（可以直接下载)
};

export default {
  powerOptions,
};
