<template>
  <div class="tabs">
    <div class="leftBtn iconbtn" @click="leftbtnFun">
      <el-icon>
        <ArrowLeft/>
      </el-icon>
    </div>
    <div class="content">
      <div class="centerDiv">
        <div class="flex-box" ref="boxWidth">
          <div class="flex-content" ref="ctnWidth">
            <div v-for="(item, index) in tabsData" :key="index" :id="item.navigatTo">
              <SubTabList :tabDatas="item" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rightBtn iconbtn" @click="rightbtnFun">
      <el-icon>
        <ArrowRight />
      </el-icon>
    </div>
    <el-popover
      placement="bottom"
      :width="150"
      trigger="hover"
      popper-class="mypopper"
      v-model:visible="visible"
    >
      <template #reference>
        <div class="downBtn iconbtn">
          <el-icon>
            <ArrowDown />
          </el-icon>
        </div>
      </template>
      <div class="downBtnlists">
        <ul>
          <li v-for="item in gridData" :key="item" @click="closeOfDownBTN(item.icon)">
            <i class="downclosebtn" :class="item.icon"></i>
            {{item.desc}}
          </li>
        </ul>
      </div>
    </el-popover>
  </div>
</template>
<script>
import {
  reactive, toRefs, ref, onMounted, nextTick, watchEffect, inject,
} from 'vue';
import { ArrowLeft, ArrowRight, ArrowDown } from '@element-plus/icons';
import { useRouter } from 'vue-router';
import SubTabList from './SubTabList.vue';
import { scrollMoveAnimate, tabsScrollPosation } from '../../utils/animate';
import downBtnFun from './hooks/downBtnFun';

export default {
  components: {
    ArrowLeft,
    ArrowRight,
    ArrowDown,
    SubTabList,
  },
  setup() {
    const router = useRouter();
    // const store = useStore();
    /**
		 * 数据
		 */
    const boxWidth = ref(0); // 监听Tabs中的外div宽度
    const ctnWidth = ref(0); // 监听Tabs中的内空div scroll宽度
    const datas = reactive({
      currentPath: '', // 当前编辑的标签
      subMenus: inject('subMenusBus'), // 当前编辑的标签
      tabsData: inject('tabsDataBus'), // tabs栏
      boxWidthVal: 0, // 获取ref中boxWidth存值
      ctnWidthVal: 0, // 获取ref中ctnWidth存值
      visible: false,
    });

    const gridData = reactive([
      {
        icon: 'el-icon-back',
        desc: '关闭左侧所有标签',
      },
      {
        icon: 'el-icon-right',
        desc: '关闭右侧所有标签',
      },
      {
        icon: 'el-icon-close',
        desc: '关闭其他标签',
      },
      {
        icon: 'el-icon-circle-close',
        desc: '关闭全部标签',
      },
    ]);
    /**
		 * 方法
		 */
    const downBtnFuns = downBtnFun();
    const myMethods = reactive({
      /**
			 * 注意几点：
			 * 1，外div(以下简称boxWidth）css是一个	display: inline-block;
			 * 2，内div（以下简称ctnWidth)css 是一个 display: inline-flex;
			 * 3，scrollLeft作用域在boxWidth中
			 * 4，需要mounted中设置wind.onresize事件捕获div元素
			 * 5，触发点击事件时需要重新再捕获下div元素
			 */
      // tab向右滑动按钮事件
      rightbtnFun() {
        datas.ctnWidthVal = ctnWidth.value.scrollWidth;
        datas.boxWidthVal = boxWidth.value.offsetWidth;
        const { ctnWidthVal, boxWidthVal } = datas;
        // scrollLeft需要在外div设置
        // 先获取scrollLeft的距离
        const crntScrollLeft = boxWidth.value.scrollLeft;
        // ctnWidthVal - boxWidthVal 为可scrollLeft的最大值
        const MaxSclLeftVal = ctnWidthVal - boxWidthVal;
        if (crntScrollLeft + boxWidthVal < ctnWidthVal) {
          if (boxWidthVal < MaxSclLeftVal) {
            // 说明有多页
            scrollMoveAnimate(crntScrollLeft, crntScrollLeft + boxWidthVal, 10, boxWidth.value);
            // boxWidth.value.scrollLeft += boxWidthVal;
          } else {
            // 说明只有一页
            scrollMoveAnimate(crntScrollLeft, MaxSclLeftVal, 10, boxWidth.value);
            // boxWidth.value.scrollLeft = MaxSclLeftVal;
          }
        }
      },
      // tab向左滑动按钮事件
      leftbtnFun() {
        datas.ctnWidthVal = ctnWidth.value.scrollWidth;
        datas.boxWidthVal = boxWidth.value.offsetWidth;
        const { ctnWidthVal, boxWidthVal } = datas;
        const leftScrollWidth = boxWidth.value.scrollLeft;

        if (ctnWidthVal > boxWidthVal && leftScrollWidth > 0) {
          if (leftScrollWidth > boxWidthVal) {
            // 说明有多页
            scrollMoveAnimate(leftScrollWidth, leftScrollWidth - boxWidthVal, 10, boxWidth.value);
            // boxWidth.value.scrollLeft -= boxWidthVal;
          } else {
            // 说明只有一页
            scrollMoveAnimate(leftScrollWidth, 0, 10, boxWidth.value);
            // boxWidth.value.scrollLeft = -leftScrollWidth;
          }
        }
      },

      // 向tabsData添加每个tab的offsetLeft信息
      async updateTabsDataOffsetLeft() {
        await nextTick(() => {
          const v = ctnWidth.value.children;
          if (v.length) {
            // 防止不存在的路径输入
            Object.values(v).forEach((e) => {
              datas.tabsData[e.id].tabOffsetLeft = e.offsetLeft;
              datas.tabsData[e.id].tabOffsetWidth = e.offsetWidth;
            });
            const options = {
              boxWidthVal: boxWidth.value.offsetWidth,
              sLeftBox: boxWidth.value.scrollLeft,
              fn: boxWidth.value,
            };
            if (Object.keys(datas.tabsData).includes(datas.currentPath)) {
              tabsScrollPosation(datas.tabsData[datas.currentPath], options);
            }
          }
        });
      },

      // down下拉关闭按钮事件
      closeOfDownBTN(e) {
        const options = { currentShowPage: datas.currentPath, method: e };
        // store.commit('tabs/DOWN_CLOSE_METHODS', options);
        downBtnFuns.downCloseMethods(options);
        // store.commit('navBarList/DOWN_CLOSE_METHODS', options);
        datas.visible = false;
        if (e === 'el-icon-circle-close') {
          router.replace('/').catch((err) => {
            console.error(err);
          });
        }
      },
    });
    router.afterEach((to) => {
      datas.currentPath = to.path;
    });
    onMounted(
      // 当数据挂载到页面后同时挂载窗口变化监听事件
      (window.onresize = () => {
        // scrollWidth>clientWidth。
        // scrollWidth为实际内容的宽度。
        // clientWidth是内容可视区的宽度。
        // offsetWidth是元素的实际宽度。
        datas.ctnWidthVal = ctnWidth.value.scrollWidth;
        datas.boxWidthVal = boxWidth.value.offsetWidth;
      }),
    );

    watchEffect(() => {
      //   // 当tabDats数据有变动时所操作:
      //   // 1,添加socrllLeft的值，
      //   // 2,改变当前点击后所显示的位置
      if (datas.currentPath) {
        myMethods.updateTabsDataOffsetLeft();
      }
    });

    return {
      boxWidth,
      ctnWidth,
      ...toRefs(datas),
      ...toRefs(myMethods),
      // ...toRefs(gridData),
      gridData,
    };
  },
};
</script>
<style lang="scss" scope >
.tabs {
	width: 100%;
	height: 40px;
	display: flex;
	background: $white;
	.iconbtn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 35px;
		height: 40px;
		border-right: 1px solid $light;
		font-size: 20px;
		.el-icon {
			color: $buttomcolor;
			cursor: pointer;
		}
		&:hover {
			background-color: $gray;
		}
	}
	.content {
		justify-content: start;
		align-items: center;
		flex: 1;
		border-right: 1px solid $light;
		height: 40px;
		overflow: hidden;
		.centerDiv {
			display: flex;
			align-items: center;
			height: 40px;
		}
	}
}
.flex-box {
	width: 100%;
	height: 40px;
	display: inline-block;
	justify-content: flex-start;
	overflow-x: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	position: relative;
	&::-webkit-scrollbar {
		display: none !important;
		width: 0;
	}
	.flex-content {
		position: absolute;
		display: inline-flex;
		transition: all 0.5s cubic-bezier(0, 1.04, 0.94, 0.93);
	}
}

.mypopper {
	padding: 5px 0 !important;
	font-size: 13px !important;
	// text-align: left;
}
.downBtnlists {
	ul {
		padding: 3px 0;
		li {
			padding: 5px 0 5px 10px;
			line-height: 18px;
			text-align: left;
			transition: all 0.3s ease-in;
			&:hover {
				background-color: rgba($color: $heardbgcolor, $alpha: 0.1) !important;
				color: $heardbgcolor;
			}
			.downclosebtn {
				// margin-right: 5px;
			}
		}
	}
}
</style>
