// 滚动条动画
/**
 * 1. fromV 开始值
 * 2. toV 结束值
 * 3. step 每步执行多少
 * 4. fn 为$ref.value的值（例如：xxx.value)
*/
const scrollMoveAnimate = (fromVal, toVal, step, fn) => {
  const fns = fn;
  let fromTempVal = fromVal;
  // 向左滑动,leftButton
  if (fromTempVal > toVal) {
    const itv = setInterval(() => {
      fromTempVal -= step;
      if (fromTempVal < toVal) {
        clearInterval(itv);
      }
      fns.scrollLeft = fromTempVal;
    }, 1);
  } else {
    // 向右滑动 ，rightButton
    const itv = setInterval(() => {
      fromTempVal += step;
      if (fromTempVal > toVal) {
        clearInterval(itv);
      }
      fns.scrollLeft = fromTempVal;
    });
  }
};

/**
 *tab显示定位
 * @param {objact} tabsData
 * @param {string} currentPath
 *
 * 1.当前元素>=offsetLeft&&当前元素.offsetLeft+当前元素.offsetWidth<=boxWidth.offsetWidth+offsetLeft 在展示位置内，则无需动
 * 2.当前元素<offsetLeft 在左侧隐藏：
 *   废除：条件，ctnWidth.offsetWidth-当前元素.offsetLeft>boxWidth.offsetWidth 则以当前元素的从scrollLeft开始至当前元素.offsetLeft
 *   以上废除是原因是最一个显示是一直靠最右侧的。
 *   则以当前元素的从scrollLeft开始至当前元素.offsetLeft
 * 3.当前元素+当前元素.offsetWidth>boxWidth.offsetWidth+offsetLeft 在右侧隐藏：
 *   则以scrollLeft开始至当前元素.offsetLeft
 * 4. options:{ctnWidthVal:xxx,boxWidthVal:xxx,sLeftBox:xxx}
 */
const tabsScrollPosation = (datas, options) => {
  try {
    const { tabOffsetLeft, tabOffsetWidth } = datas;
    const {
      boxWidthVal, sLeftBox, fn,
    } = options;
    if (JSON.stringify(tabOffsetLeft) !== undefined) {
      // 隐藏在左侧或隐藏在右侧
      if (tabOffsetLeft < sLeftBox || ((tabOffsetLeft + tabOffsetWidth) > boxWidthVal + sLeftBox)) {
        scrollMoveAnimate(sLeftBox, tabOffsetLeft, 5, fn);
      }
    }
  } catch (error) {
    console.error(error);
  }
};
module.exports = {
  scrollMoveAnimate, tabsScrollPosation,
};
