import TabClkClass from '../../components/tabslist/class/tabClkClass';
import UtilsClass from '../../utils/utilsClass/utilsClass';
import ConstClass from './constClass';
// 操作的nav菜单栏的展示
export default class MenuListClass {
  #router;

  constructor(storeDatas, setFilterOfContainer, router) {
    this.storeDatas = storeDatas; // 原始的左侧菜单栏（总)
    this.showMenuList = ''; // 根据用户权限展示菜单
    this.userAuth = ConstClass.USER_UNLOGIN; // 初始化为未登陆状态
    this.setFilterOfContainer = setFilterOfContainer;
    this.#router = router;
  }

  // navFilterMenuList 根据用户权限筛选内容
  // 主要有 权限下菜单栏、白名单
  navFilterMenuList = () => {
    try {
      const menuListAry = [];
      const tempWhiteListBus = []; // 临时白名单
      Object.values(this.storeDatas.menuListBusModel).forEach((obj) => {
        const subMenuAry = []; // 临时子菜单数组
        const prevMenuObj = { title: obj.title, iconName: obj.iconName }; // 定义根菜单
        // 遍历subMenu子菜单，获取子菜单
        obj.subMenu.forEach((subObj) => {
          // 根据用户权限截取访问页
          if ((subObj.visitAuth & this.userAuth) === subObj.visitAuth) {
            // 符合展示菜单
            subMenuAry.push(subObj);
            tempWhiteListBus.push(subObj.navigatTo);
            this.storeDatas.subMenusBus[subObj.navigatTo] = subObj; // 添加权限下的子菜单
          }
        });
        if (subMenuAry.length !== 0) {
          // 必须有值才添加
          prevMenuObj.subMenu = subMenuAry;
          menuListAry.push(prevMenuObj);
        }
      });

      Object.assign(this.storeDatas, { menuListBus: menuListAry }); // 添加展示菜单
      [...this.storeDatas.whiteListBus] = tempWhiteListBus; // 添加白名单

      // const currentPath = this.#router.currentRoute.value.path;
      const currentPath = this.storeDatas.currentShowPageBus.name;
      UtilsClass.updateTabsData(this.storeDatas, currentPath);
    } catch (error) {
      console.error(error);
    }
  };

  // setElementInfo 设置元素信息
  setElementInfo = (res) => {
    try {
      // 有可能visitAuth无值，无值时设置为未登陆用户
      const authCode = this.bitTransformInt(res.visitAuth || '1001');
      Object.assign(this.setFilterOfContainer, {
        userNickname: res.userNickname === '' ? '登陆' : res.userNickname,
        isLogin: res.isLogin, // 用户是登陆还是退出
        visitAuth: authCode,
        loginedFunc: null, // 置空登陆成功操作函数
      });
      this.userAuth = authCode;
      this.navFilterMenuList(); // 将当前用户权限的菜单筛选出来展示
      this.delTabsMenuList(); // 根据权限删除tabs中不可访问的tab
    } catch (error) {
      console.error(error);
    }
  };

  // 当前展示页为非访问页，则向后退，或直到'/'主页
  delTabsMenuList = () => {
    try {
      // 不包含白名单中的tab将其关闭
      const tabClkClass = new TabClkClass(this.storeDatas, null, this.#router);
      // 筛查tabs中的tab
      Object.keys(this.storeDatas.tabsDataBus).forEach((key) => {
        // 要排除的tab
        if (!this.storeDatas.whiteListBus.includes(key)) {
          tabClkClass.tabDatas = this.storeDatas.tabsDataBus[key];
          tabClkClass.closeTabFunc();
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  // bitTransformInt将二进制转换为int
  bitTransformInt = (bitString) => parseInt(bitString, 2);

  /** closeCurrentPageOpenLogin 关闭当前页打开登陆页
   * 自动获取当前页面
   */
  closeCurrentPageOpenLogin = () => {
    try {
      const urlKey = this.storeDatas.tabsDataBus[this.#router.currentRoute.value.path];
      const tabClkClass = new TabClkClass(this.storeDatas, urlKey, this.#router);
      tabClkClass.closeTabFunc();

      // 展示登陆页
      this.setFilterOfContainer.setFilter(0.5);
      this.setFilterOfContainer.loginRunLoadFunc(); // 登陆将显示登陆弹窗
    } catch (error) {
      console.error(error);
    }
  };

  // hideWindowOfLoginSuccess 登陆/注册成功隐藏登陆窗体
  // res 成功登陆用户信息
  hideWindowOfLoginSuccess = (res) => {
    try {
      this.hideLoginWindow(true);
      this.setElementInfo(res); // 重新设置当前用户展示的菜单栏
    } catch (error) {
      console.error(error);
    }
  };

  // hideLoginWindow 隐藏登陆窗体
  /**
   *
   * @param {bool} boolVal  是否登陆true:登陆(默认),false：未登陆
   */
  hideLoginWindow = (boolVal = true) => {
    // 登陆窗不可见
    this.setFilterOfContainer.isLogin = boolVal;
    this.setFilterOfContainer.clearLoginOldFormValue(); // 重置表单为初始值
    this.setFilterOfContainer.currentTabName = 'login';
    this.setFilterOfContainer.clearFilter(); // 清除模糊
  };

  /** delkeepAliveLists 删除缓存中的指定数据
   *
   * @param {*} pathName 当前页路径含/前缀
   */
  delkeepAliveLists = (pathName) => {
    if (pathName !== '/') {
      // 去除前缀/
      const lowPath = pathName.replace('/', '').toLowerCase();
      const idx = this.storeDatas.keepAliveLists.indexOf(lowPath);
      if (idx !== -1) {
        this.storeDatas.keepAliveLists.splice(idx, 1);
      }
    }
  };
}
