// import { ElMessage } from 'element-plus';
import {
  reactive,
  provide,
} from 'vue';
import ConstClass from '../../../store/class/constClass';

export default function (filterStyle) {
  const setFilterOfContainer = reactive({
    isQueryAuth: false, // 是否向服务器发送过校验用户何种状态（true，校验过，false：未校验),用于一次向服务索取数据
    isLogin: false, // 判断是否登陆 true 显示用户名,false 显示未登陆
    userNickname: '登陆', // 用户登陆信息
    visitAuth: ConstClass.USER_UNLOGIN, // 用户访问者权限[未登陆9，登陆3，管理者7]
    showDialogOfLogin: false, // 是否显示整个登陆弹窗 false:隐藏 true：显示
    currentTabName: 'login', // 当前登陆tab名称 login默认，regist
    redirect: '', // 需要重定向页面

    // 中间方法件
    loadCustomsdocsState: true, // 制作报关文件查询文件状态【fase：无需加载，true：需要重新加载】
    loadOnPayLineState: true, // 在线支付是否加载过数据【fase：无需加载，true：需要重新加载】
    loadHomeStatistics: true, // 主页中统计数据是否需要加载【fase：无需加载，true：需要重新加载】
    loadHomeFunc: null, // 主页中统计数据加载函数
    clearHomeFunc: null, // 清空主页数据

    // 共享样式事件与子组件使用
    setFilter: (delayTime = 0.5) => { // 弹窗模糊样式
      setFilterOfContainer.showDialogOfLogin = true;
      filterStyle.value = `-webkit-transition: all .5s;transition:all ${delayTime}s;filter;filter:blur(2px)`;
    },

    clearFilter: () => { // 清除弹窗样式
      filterStyle.value = '';
      setFilterOfContainer.showDialogOfLogin = false;
    },

    // showLoginDialog 展示登陆框
    showLoginDialog: () => {
      setFilterOfContainer.isLogin = false;
      setFilterOfContainer.userNickname = '登陆';
      setFilterOfContainer.setFilter(0.5);
      setFilterOfContainer.loginRunLoadFunc();
    },

    loginDatas: {
      imageURL: '', // 验证码图片
      vfyErrorMsg: '', // 验证码错误提示
    },
    registDatas: {
      mbInputText: '获取验证码', // 手机验证码输入方式
    },

    clearRegistOldFormValue: null, // 关闭 清空注册原有的值
    clearLoginOldFormValue: null, // 关闭 清空登陆原有的值
    setLoginImageDatas: null, // 设置登陆验证图片信息
    setCurrentModel: null, // 设置tabs-item的值
    loginRunLoadFunc: null, // 注册页面加载验证码（判断是否调用loadVerifyCode()
    accountingDataFunc: null, // 登陆成功后加载已支付数据
    onlinePayFunc: null, // 登陆成功后加载在线支付数据
  });
  provide('setFilterOfContainer', setFilterOfContainer);

  return {
    setFilterOfContainer,
  };
}
