import { ElMessageBox, ElMessage } from 'element-plus';
import AppRouterClass from '../components/app/class/appRouterClass';
import QueryAuthClass from '../store/class/queryAuthClass'; // 获取当前用户权限

export default class HeaderRouterClass {
  constructor(router, setFilterOfContainer, storeDatas) {
    this.router = router;
    this.setFilterOfContainer = setFilterOfContainer;
    this.storeDatas = storeDatas;
    this.queryAuthClass = new QueryAuthClass(
      this.storeDatas,
      this.setFilterOfContainer,
      this.router,
    );
    this.appRouterClass = new AppRouterClass(storeDatas, router);
  }

  // 路由前置守卫
  initBeforeEach = () => {
    this.router.beforeEach((to, from, next) => {
      if (!this.setFilterOfContainer.isQueryAuth) {
        // 刷新时才运行 判断是否向服务器加载过数据
        this.queryAuthClass.queryAuthToken().then(() => {
          if (this.storeDatas.whiteListBus.includes(to.path)) {
            this.rightfulPahtOption(to, from, next);
          } else {
            // 非法地址，跳转至主页
            this.router.replace({ path: '/' }).catch((err) => {
              ElMessage.error(err);
            });
          }
        });
      } else if (this.storeDatas.whiteListBus.includes(to.path)) {
        // 跳转的地址存在
        this.rightfulPahtOption(to, from, next);
      } else {
        // 非法地址，跳转至主页
        this.router.replace({ path: '/' }).catch((err) => {
          ElMessage.error(err);
        });
      }
    });
  };

  // initCreateCustomsUuid 初始化createCustoms中的UUID
  initCreateCustomsUuid = (to) => {
    // 对'/createcustoms'执行条件：无值UUIDkey，或UUID的值为空,17位数值) 赋值新的UUID
    if (to.path === '/createcustoms') {
      const queryObj = to.query;
      const newUuidFunc = (params) => {
        // 创建uuid数据
        this.router
          .replace({
            path: to.path,
            query: {
              params,
            },
          })
          .catch((err) => {
            ElMessage.error(err);
          });
      };
      if (!Object.prototype.hasOwnProperty.call(queryObj, 'params')) {
        // 不存在params数据，uuid也不存在
        newUuidFunc(window.btoa(encodeURIComponent(JSON.stringify({
          uuid: `${new Date().valueOf()}${((Math.random() * 0x1000) | 0).toString().padStart(4, 0)}`,

        }))));
      } else {
        // 存在params参数 ，解析后判断是否存在uuid且是否正确
        try {
          const paramsObj = JSON.parse(decodeURIComponent(window.atob(queryObj.params)));
          if ((!Object.prototype.hasOwnProperty.call(paramsObj, 'uuid')) || ['', null, undefined].includes(paramsObj.uuid) || (!/^1[0-9]{16}$/.test(paramsObj.uuid))) {
            // uuid不存属性，为空或格式不对重新创建uuid
            paramsObj.uuid = `${new Date().valueOf()}${((Math.random() * 0x1000) | 0).toString().padStart(4, 0)}`;
            newUuidFunc(window.btoa(encodeURIComponent(JSON.stringify(paramsObj))));
          }
        } catch (error) {
          // 用户恶意修改数据
          ElMessage.error('请正确传入参数！');
          newUuidFunc(window.btoa(encodeURIComponent(JSON.stringify({ uuid: `${new Date().valueOf()}${((Math.random() * 0x1000) | 0).toString().padStart(4, 0)}` }))));
        }
      }
    }
  };

  // rightfulPahtOption 判定合法路径跳转事件
  rightfulPahtOption = (to, from, next) => {
    this.appRouterClass.updateMethod(to); // 向tabs添加数据
    if (!this.setFilterOfContainer.isQueryAuth) {
      // 第一次刷新
      this.setFilterOfContainer.isQueryAuth = !this.setFilterOfContainer.isQueryAuth; // 标记刷新过数据
      this.initCreateCustomsUuid(to); // 初始UUID
      this.storeDatas.tabsDataBus[to.path].query = to.query; // 备份跳转的query至tabs对应的key
    } else {
      this.initCreateCustomsUuid(to);
    }

    // 记录所有点击过来的query参数 例如：?params=test&&test=222
    if (Object.prototype.hasOwnProperty.call(this.storeDatas.tabsDataBus, from.path)) {
      this.storeDatas.tabsDataBus[to.path].query = to.query;
      this.storeDatas.tabsDataBus[from.path].query = from.query;
    }
    // 合法地址
    next();
  };

  // loginOutFunc 退出登陆
  loginOutFunc = () => {
    if (this.setFilterOfContainer.isLogin === true) {
      // 先判断判断值tabs中数据是否有未关闭的
      if (Object.keys(this.storeDatas.tabsDataBus).length === 1) {
        // 只剩余主页，可直接关闭
        this.queryAuthClass.logOutFunc('退出成功');
      } else {
        // 有未关闭的tab，需要提示
        // 提示
        ElMessageBox.confirm('退出登陆后将关闭所有内容，确定是否需要继续退出！', {
          title: '提示',
          type: 'success',
          showClose: false,
          confirmButtonText: '确定',
          cancelButtonText: '返回',
          closeOnClickModal: false, // 点击蒙层取消
          closeOnPressEscape: false, // 禁止按ESC
        })
          .then(() => {
            // 点击了确定
            // 只有登陆后才操作
            this.queryAuthClass.logOutFunc('退出成功');
          })
          .catch(() => {
            // 点击了返回
            ElMessage({
              message: '取消退出登陆',
              type: 'success',
            });
          });
      }
    } else {
      ElMessage({
        message: '当前未登陆',
        type: 'success',
      });
    }
  };
}
