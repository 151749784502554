import {
  // ElMessageBox,
  ElMessage,
  // ElNotification
} from 'element-plus';
import md5 from 'blueimp-md5';
import myAxios from '../../../utils/axios/index_v2';
import SessionIsExpireClass from './sessionIsExpire';
import MenuListClass from '../../../store/class/menuListClass';
import CreateCustomsClass from '../../createcustoms/class/createCustomsClass';

export default class LoginClass extends SessionIsExpireClass {
  #router

  constructor(loginDatas, refNode, createCustomsDatasBus, setFilterOfContainer, storeDatas, router) {
    super('login', setFilterOfContainer);
    this.createCustomsDatasBus = createCustomsDatasBus;
    this.caller = 'login';
    this.datas = loginDatas;// 注册表单数据
    this.refNode = refNode;// 当前组件ref
    this.setFilterOfContainer = setFilterOfContainer;
    this.storeDatas = storeDatas;
    this.#router = router;
    this.menuListClass = new MenuListClass(this.storeDatas, this.setFilterOfContainer, this.router);// 侧边栏bar
  }

  // VerifyFormBeforeSend 校验表的数据是否正确
  // @retuns 返回true:有错误\不能发送,false:校验正确\发送
  VerifyFormBeforeSend = async () => {
    const vfyKeys = Object.keys(this.datas);
    let tempBL = false;
    // 校验表单内容正确性
    await this.refNode.validateField(vfyKeys, (err) => {
      if (err) tempBL = true;
    });
    return tempBL;
  }

  /** VerifySotrageBeforeSend 校验是否过期
    * 可能会出现如下 几种情况：
    * 用户删除了seesionStorage "verifyStatus"：
    * 用户清空了verifyStatus 的内容
    * @returns true:过期，false：未过期
   */
  VerifySotrageBeforeSend = () => {
    // 校验有验证码有效性
    const status = sessionStorage.getItem('verifyStatus');
    if (status !== '' && status !== null) {
      const vfyStatusOBJ = JSON.parse(window.atob(status));
      const vfyStamp = Number(vfyStatusOBJ[this.caller]);
      // 为数字且有验证码
      if (!Number.isNaN(vfyStamp)) {
        if (vfyStamp <= new Date().valueOf()) {
          this.setFilterOfContainer.loginDatas.vfyErrorMsg = '验证码已过期,请重新输入';
          this.LoadVerifyCode();
          this.FocusNode(this.refNode, 2);// 定位至输入框
          return true;
        }
      }
    }
    return false;
  }

  // SubimtFunc 向服务器提交登陆数据
  SubimtFunc = () => {
    const { loginDatas } = this.setFilterOfContainer;
    loginDatas.vfyErrorMsg = '';// 清空自定义报错信息
    const that = this;
    this.VerifyFormBeforeSend().then((isCorrect) => { // 验证表单
      if (isCorrect) { // 有错误
        return;
      }
      if (this.VerifySotrageBeforeSend()) { // 验证码是否过期
        return;
      }
      // 先校验用户数据
      myAxios().initAxios({
        method: 'POST',
        url: '/login',
        Headers: { Accept: 'application/json; charset=utf-8' },
        data: {
          caller: that.caller,
          mobile: that.datas.mobile,
          password: md5(that.datas.password),
          verify_code: that.datas.verifyCode,
        },
      }).then((res) => {
        if (res.code === 200) {
          // 登陆成功
          this.menuListClass.hideWindowOfLoginSuccess(res);// 隐藏登陆窗体
          ElMessage({
            type: 'success',
            message: '登陆成功',
            duration: 3000,
          });
          // 登陆成功后要操作的事务
          that.loginedFunc();
        }
      }).catch((err) => {
        // 登陆与验证码情况："code":400(非正常访问)|1007(验证码错误)|4007(登陆信息错误)|200(登陆成功)
        // 验证码过期更新情况:"code":1004(验证码过期，vfyBase64&expStamp有值））|200（未过期,vfyBase64&expStamp为空）
        if (err.code === 1007) { // 验证码错误
          if (Object.prototype.hasOwnProperty.call(err, 'results') && err.results.code === 1004) {
            loginDatas.imageURL = `data:image/jpg;base64,${err.results.base64Img}`;
            // 更新seesionStorage
            that.UpdateSessionSotrage(that.caller, err.results.lastStamp);
          }
          loginDatas.vfyErrorMsg = err.message;
          that.FocusNode(that.refNode, 2);// 定位至输入框
        } else if (err.code === 4007) { // 登陆信息错误
          that.FocusNode(that.refNode, 1);// 定位至密码框
          ElMessage({
            type: 'error',
            message: `登陆失败：${err.message}`,
            duration: 3000,
          });
        } else if (err.code === 400) { // 参数出错
          ElMessage({
            type: 'error',
            message: '请输入正确的参数',
            duration: 2000,
          });
          that.FocusNode(that.refNode, 0);// 定位至手机
        }
      });
    });
  }

  // 登陆后要操作的函数(主要用于将临时用户的数据写入指定用户)
  loginedFunc = () => {
    Object.assign(this.setFilterOfContainer, {
      loadCustomsdocsState: true, // 制作报关文件查询文件状态【fase：无需加载，true：需要重新加载】
      loadOnPayLineState: true, // 在线支付是否加载过数据【fase：无需加载，true：需要重新加载】
      loadHomeStatistics: true, // 主页中统计数据是否需要加载【fase：无需加载，true：需要重新加载】
    });
    switch (this.#router.currentRoute.value.path) {
      case '/createcustoms':
        if (this.createCustomsDatasBus !== undefined) {
          const createCustomsClass = new CreateCustomsClass(this.createCustomsDatasBus, this.setFilterOfContainer, this.storeDatas, this.#router);
          // 先将redis中的数据写入sql中
          createCustomsClass.handelName = createCustomsClass.setDataByUuid;
          createCustomsClass.axiosFunc();
          // 重新向后台获取数据
          createCustomsClass.queryDataFromRouter();
        }
        break;
      case '/accountingdetail':// 账务明细
        // 登陆成功后执行加载已支付订单
        if (this.setFilterOfContainer.accountingDataFunc !== null) this.setFilterOfContainer.accountingDataFunc();
        break;

      case '/onlinepay':// 在线支付
        if (this.setFilterOfContainer.onlinePayFunc !== null) this.setFilterOfContainer.onlinePayFunc();
        break;
      case '/':
        this.setFilterOfContainer.loadHomeFunc();
        break;
      default:
        break;
    }
  }
}
