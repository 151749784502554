<template>
  <el-container :style="filterStyle">
    <el-aside :width="storeDatas.sideBarObj.widthVal">
      <div class="text-white"></div>
      <Nav />
    </el-aside>
    <el-container>
      <el-header>
        <Header />
      </el-header>
      <div class="tabslists">
        <TabsList />
      </div>
      <el-main ref="elMainRef">
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in" appear>
            <!-- keep-alive 中也可以匹配正则/正则名/，keepaliveName为前缀为缓存组件 -->
            <!-- 注意：要缓存其内容，需要将name的名称与include的名字相符，否则不生效 -->
            <!--主要作用：缓存点击的数据，当关闭后就不会清除缓存 -->
            <keep-alive :include="storeDatas.keepAliveLists">
              <component :is="Component" ref="compRef" />
            </keep-alive>
          </transition>
        </router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import { reactive, provide, ref } from 'vue';

// 组件文件
import Header from './views/Header.vue';
import Nav from './views/Nav.vue';
import TabsList from './components/tabslist/TabsList.vue';

// 插件组件css文件
// import '@/assets/css/cssreset.css';
import 'element-plus/dist/index.css';
// 事件总线
import storeDatasBus from './store/busDatas/storeDatasBus';
import SetFilterOfContainer from './components/loginDialog/provide/setFilterOfContainer';
// import CreateCustomsDatasBus from './components/createcustoms/store/createCustomsDatasBus.vue'; // 所有子组件操作数据

// 类
// 路由类
// 侧边栏事件
import ChangeSideBarClass from './components/nav/class/changSideBarClass';

export default {
  name: '#app',
  components: { Header, Nav, TabsList },

  setup() {
    // 判断用户是否开启cookie
    if (!navigator.cookieEnabled) {
      window.document.write('<h1>请开启COOKIE</h1>');
      return {};
    }
    const elMainRef = ref(null); // app主页$refs
    const filterStyle = ref(''); // Set App filter Style
    const compRef = ref(null);
    const headerRef = ref(null);

    // 获取compRef的数据
    function getCompRefFunc() {
      return compRef;
    }

    // 共享数据provide
    const storeDatas = reactive(storeDatasBus()); // 初始化共享数据
    const setFilterOfContainer = reactive(SetFilterOfContainer(filterStyle).setFilterOfContainer);
    const homeData = reactive({
      authorizeOrder: 0, // 电子委托
      orderOfMonth: 0, // 每月订单量
      reviewOrder: 0, // 核审单量
      totalOrder: 0, // 总订单量
      unSubmitNum: 0, // 未提交
      waitPayOrder: 0, // 待支付
    });
    // 类：根据session值初始化侧边栏的宽度
    const changeSideBarClass = new ChangeSideBarClass(storeDatas.sideBarObj);
    changeSideBarClass.reLoadPageSetSideBarWidth();

    provide('elMainRef', elMainRef); // 进度
    provide('storeDatas', storeDatas);
    provide('getCompRefFunc', getCompRefFunc);
    provide('homeData', homeData);

    return {
      storeDatas,
      compRef,
      getCompRefFunc,
      headerRef,
      elMainRef,
      filterStyle,
      setFilterOfContainer,
      homeData,
    };
  },
};
</script>
<style lang="scss" scope>
// @import '@/assets/scss/variables.scss';

body {
  background: $gray;
  font: normal 14px/1.7 'Open Sans', 'HYQiHei-25', Verdana, Tahoma, '微软雅黑', '宋体' !important;
  -webkit-text-size-adjust: 100%;
  font-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  color: #111;
  cursor: default;
  -webkit-tap-highlight-color: transparent;
  min-width: 960px !important;
  margin: 0;
  padding: 0;
}

#app {
  display: flex;
  justify-content: center;
  background: $buttomcolor;
}

.el-container {
  background-color: $light;
  max-width: 1680px !important;
  min-height: 500px !important;
  height: 100vh;
  overflow: hidden;
}

.el-header,
.el-footer {
  background-color: $heardbgcolor;
  color: var(--el-text-color-primary);
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: $dark;
  color: var(--el-text-color-primary);
  text-align: center;
  line-height: 200px;
  transition: all 0.5s ease-in-out;
  z-index: 1000;
}

.el-main {
  // background-color: #fff;
  // color: var(--el-text-color-primary);
  // text-align: center;
  // line-height: 160px;
  // --el-main-padding: 15px !important;
}

body>.el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.tabslists {
  height: 40px;
  box-shadow: 0 0 3px $hovefontcolor;
  z-index: 1000;
}

// 展示router-view

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
