import {
  reactive,
  provide, readonly, ref,
} from 'vue';
import ConstClass from '../class/constClass';
import globalmethods from '../../configs/customsdocOpBtn';

export default function () {
  const menuListBusModel = reactive([// 菜单
    {
      title: '我的工作台',
      iconName: 'el-icon-menu',
      subMenu: [
        {
          subMenuTitle: '我的工作台', navigatTo: '/', query: {}, tagName: '主页', visitAuth: ConstClass.PAGE_UNLOGIN_USER, // 限制访问者权限
        }],
    },
    {
      title: '制作报关文件',
      iconName: 'el-icon-document',
      subMenu: [
        {
          subMenuTitle: '查询', navigatTo: '/customsdocs', query: {}, tagName: '查询制作文件', visitAuth: ConstClass.PAGE_UNLOGIN_USER,
        },
        {
          subMenuTitle: '录入报关信息', navigatTo: '/createcustoms', query: {}, tagName: '录入报关信息', visitAuth: ConstClass.PAGE_UNLOGIN_USER,
        },
        {
          subMenuTitle: '审核', navigatTo: '/audit', query: {}, tagName: '审核', visitAuth: ConstClass.PAGE_ADMIN,
        },
      ],
    },
    {
      title: '账单管理',
      iconName: 'el-icon-time',
      subMenu: [
        {
          subMenuTitle: '账务明细', navigatTo: '/accountingdetail', query: {}, tagName: '账务明细', visitAuth: ConstClass.PAGE_UNLOGIN_USER,
        },
        {
          subMenuTitle: '在线支付', navigatTo: '/onlinepay', query: {}, tagName: '在线支付', visitAuth: ConstClass.PAGE_UNLOGIN_USER,
        },
        // { // desabled ：禁止显示在nav侧边导航栏中
        //   subMenuTitle: '账单明细', desabled: true, navigatTo: '/debitnotepage', query: {}, tagName: '账务明细', visitAuth: ConstClass.PAGE_LOGIN_USER,
        // },
      ],
    },
    {
      title: '我的账户',
      iconName: 'el-icon-setting',
      subMenu: [
        {
          subMenuTitle: '账户安全', navigatTo: '/usersecurity', query: {}, tagName: '账户安全', visitAuth: ConstClass.PAGE_ONLY_UNLOGIN_USER,
        },
        {
          subMenuTitle: '修改密码', navigatTo: '/updatepwd', query: {}, tagName: '修改密码', visitAuth: ConstClass.PAGE_LOGIN_USER,
        },
        {
          subMenuTitle: '资料修改', navigatTo: '/editinfo', query: {}, tagName: '资料修改', visitAuth: ConstClass.PAGE_LOGIN_USER,
        },

      ],
    },
  ]);
  const menuListBus = reactive([]); // 菜单
  const whiteListBus = reactive([]); // 白名单，防止输入不存在的网址
  const subMenusBus = reactive({}); // 存放所有快捷子菜单
  const showNavTitle = ref('/');// default-active	当前激活菜单的 index，菜单高亮子菜单
  const tabsDataBus = reactive({}); // 存放tabs(内容含每个tab坐标)
  const currentShowPageBus = reactive({ name: '' }); // 当前展示页
  // 侧边栏缩放对象
  const sideBarObj = reactive({
    widthVal: '200px', // 侧边栏宽度
    isShowNavList: 'img-navlogo_70', // 图片
    zoomBtnToptitle: '缩小 ', // 提示文字
    isCollapse: false, // 侧边栏图标：小图标true，大图标false
    navLogo: 'bigLogo', // Logo图标bigLogo|smallLogo
  });
  // 动态添加keepalive缓存
  const keepAliveLists = reactive([]);
  // key值就是对应的页面的数据存储单元，关闭tab时对应到key
  const provideElments = reactive({
    createcustoms: {}, // customsDocs点击事件的id
  });

  provide('menuListBus', menuListBus);// 左侧菜单栏（根据权限筛选过）
  provide('whiteListBus', whiteListBus);// 白名单
  provide('currentShowPageBus', currentShowPageBus);// 当前页
  provide('subMenusBus', subMenusBus);// 快捷子菜单
  provide('showNavTitle', showNavTitle);//  default-active	当前激活菜单的 index
  provide('tabsDataBus', tabsDataBus);// tabs栏

  // 暴露事件总线
  provide('keepAliveLists', keepAliveLists); // 传递给tab关闭按钮进行数据清理
  provide('provideElments', provideElments); // 传递给tab关闭按钮进行数据清理
  provide('globalmethods', readonly(globalmethods));// 定义操作按钮组

  // sidebar缩小 与放大数据
  provide('sideBarObj', sideBarObj);

  return {
    showNavTitle,
    menuListBusModel,
    menuListBus,
    whiteListBus,
    subMenusBus,
    tabsDataBus,
    currentShowPageBus,
    provideElments,
    keepAliveLists,
    sideBarObj, // 不展开，作为整体
  };
}
