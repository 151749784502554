export default class UtilsClass {
  /**  updateTabsData  更新tabsDataBus数据
      *
      * @param {Object} tabsDataBus tabs栏
      * @param {string} currentPath 当前路径
      *
      */
  static updateTabsData(storeDatas, currentPath) {
    // 判断是否在白名单中
    if (!storeDatas.whiteListBus.includes(currentPath)) {
      // 不是白名单不执行
      return;
    }
    const subMenuObj = storeDatas.subMenusBus[currentPath];// 取出当前路径对应的数据
    const navigatToName = subMenuObj.navigatTo;
    if (!storeDatas.tabsDataBus['/']) { // 首页不存在
      storeDatas.tabsDataBus['/'] = storeDatas.subMenusBus['/']; // 首先添加首页
    }
    storeDatas.tabsDataBus[navigatToName] = subMenuObj;// 添加数据
    // 修改当前active状态
    UtilsClass.activeStatus(storeDatas, navigatToName);
  }

  /** 更新缓存页面，输入时切换值还存在
     *
     * @param {*} storeDatas 共享数据
     * @param {*} pathName 路径名
     */
  static updateKeepActive(storeDatas, pathName) {
    // 除主页外，其他都添加keepactive 缓存
    if (pathName !== '/') {
      // 转换为小写并去除前缀'/'，要求组件的name为小写并且与path路径相同
      const lowPath = pathName.replace('/', '').toLowerCase();
      // 判断当前keepAlive缓存是否已存在
      if (storeDatas.keepAliveLists.indexOf(lowPath) === -1) {
        storeDatas.keepAliveLists.push(lowPath);
      }
    }
  }

  /** activeStatus 修改tabsdata中active状态
     *
     * @param {*} storeDatas 共享数据
     * @param {*} navigatToName 跳转名称
     */
  static activeStatus(storeDatas, navigatToName) {
    Object.values(storeDatas.tabsDataBus).forEach((e) => {
      const keyStr = e.navigatTo;
      if (keyStr === navigatToName) {
        storeDatas.tabsDataBus[keyStr].active = 1;
      } else {
        storeDatas.tabsDataBus[keyStr].active = 0;
      }
    });
  }
}
