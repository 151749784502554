import UtilsClass from '../../../utils/utilsClass/utilsClass';

export default class AppRouterClass {
  #router;

  #storeDatas

  constructor(storeDatas, router) {
    this.#router = router;
    this.#storeDatas = storeDatas;// 共享数据provide
  }
  /**
   * 设置meta标签（keywords,description)
   * @param {*} routerTo 路由器
   * @param {*} name 标签名称
   * @param {*} val 标签内容
   */

  setAttributeFunc = (routerTo, name, val) => {
    if (val) {
      const el = document.querySelector(`meta[name="${name}"]`);
      if (el) {
        el.setAttribute('content', routerTo.meta[name]);
      } else {
        const meta = document.createElement('meta');
        meta.setAttribute('name', name);
        meta.setAttribute('content', val);
        const viewport = document.querySelector('meta[name="viewport"]');
        viewport.parentNode.insertBefore(meta, viewport.nextSibling);// 在META.viewport之后添加元素
      }
    }
  }

  // updateMethod 创建tabs菜单及title数据
  updateMethod = (routerTo) => {
    const currentPath = routerTo.path;
    // 设置菜单高亮子菜单
    this.#storeDatas.showNavTitle = currentPath; // 当前激活菜单

    document.title = routerTo.meta.title;//  设置页面title
    this.setAttributeFunc(routerTo, 'description', routerTo.meta.description);// 设置meta之description
    this.setAttributeFunc(routerTo, 'keywords', routerTo.meta.keywords);// 设置meta之keywords

    // 判断在当前目录内的path
    if (Object.prototype.hasOwnProperty.call(this.#storeDatas.subMenusBus, currentPath)) {
      // 设置当前路径名
      this.#storeDatas.currentShowPageBus.name = currentPath;
      // 更新tabs数据及样式
      UtilsClass.updateTabsData(this.#storeDatas, currentPath);
      // 除主页外，其他都添加keepactive 缓存
      UtilsClass.updateKeepActive(this.#storeDatas, currentPath);// 不操作，全部保存
    }
  }
}
