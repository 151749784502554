<template>
  <div class="subTabList">
    <div :class="tabStatus" @click="tabClk">
      <span>{{tabDatas.tagName}}</span>
      <i v-show="tabDatas.navigatTo!=='/'" class="colseBtn" @click.stop="closeTab()">
        <el-icon>
          <close />
        </el-icon>
      </i>
    </div>
  </div>
</template>
<script>
import {
  reactive, computed, toRefs, inject,
} from 'vue';
import { Close } from '@element-plus/icons';
// import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import TabClkClass from './class/tabClkClass';

export default {
  components: { Close },
  props: ['tabDatas'],
  setup(props) {
    const router = useRouter();
    const storeDatas = inject('storeDatas'); // 共享数据
    // 数据
    const datas = reactive({
      tabDatas: props.tabDatas, // 创建tabs的单个数据
    });

    // 实例化类
    const tabClkClass = new TabClkClass(storeDatas, datas.tabDatas, router);

    const myMethods = reactive({
      /**
			 * 思路：
			 * 1，点击tab关闭事件;
			 * 2，判断是关闭正在显示tab还是关闭的其他未显示tab
			 * 3，关闭其他tab，将tabsDatasBus中对应的pathName删除
			 * 4，关闭正在显示的tab，需要更新currentShowPageBus设置为上一个tab
			 * 5，repalce事件重新加载页
			 */
      // 关闭按钮
      closeTab: () => {
        tabClkClass.closeTabFunc();
      },
      // 点击tab事件
      tabClk: () => {
        // 修改router-view中的main内容
        tabClkClass.tabClkFunc();
      },
    });

    const { tabDatas } = datas;

    // 设置tab样式类
    const tabStatus = computed(() => {
      if (tabDatas.active === 1) {
        return 'tabarName tabarNameActive';
      }
      return 'tabarName';
    });

    return {
      ...toRefs(datas),
      ...toRefs(myMethods),
      storeDatas,
      tabStatus,
    };
  },
};
</script>
<style lang="scss" scoped>
.subTabList {
	height: 40px;
	span {
		white-space: pre;
		height: 40px;
		line-height: 40px;
		font-size: 14px;
	}
	.tabarName {
		//默认时状态
		height: 40px;
		font-size: 10px;
		padding: 0 10px 0 30px;
		border-right: 1px solid $light;
		text-align: center;
		position: relative;
		line-height: 40px;
		font-weight: bold;
		color: $hovefontcolor;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		&::before {
			content: '';
			width: 7px;
			height: 7px;
			background: $hovefontcolor;
			border-radius: 50%;
			position: absolute;
			left: 10%;
			top: 40%;
		}
		&:hover {
			//hove时状态
			color: $buttomcolor;
			transition: all 0.3s ease-in-out;
			background: $gray;
			&::before {
				background: $submenucolor;
			}
		}
	}
	.tabarNameActive {
		//当前编辑时状态
		color: rgba($heardbgcolor, 0.8);
		background-color: rgba($heardbgcolor, 0.1);
		&::before {
			background-color: rgba($heardbgcolor, 0.8);
		}
		&:hover {
			color: $heardbgcolor;
			background-color: rgba($heardbgcolor, 0.1);
		}
	}
	.colseBtn {
		width: 16px;
		height: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		margin-left: 5px;
		transition: all 0.3s ease-in;
		&:hover {
			color: white;
			background-color: $red;
		}
	}
}
</style>
