<template>
  <div class="leftNav">
    <div @click="navigatToFunc(storeDatas.menuListBus[0].subMenu[0])" :class="storeDatas.sideBarObj.navLogo"></div>
    <el-menu text-color="white" :default-active="storeDatas.showNavTitle" active-text-color="#ffd04b"
      class="el-menu-vertical-demo" :collapse="storeDatas.sideBarObj.isCollapse" mode="vertical" :unique-opened="true">
      <!-- :index:必须为一个字符串，所以加idx_1.... -->
      <el-sub-menu v-for="(items, indexs ) in storeDatas.menuListBus" :key="indexs" :index="'idx_' + indexs"
        popper-class="submenuself">
        <!-- 菜单栏 -->
        <template #title>
          <i :class="items.iconName"></i>
          <span>{{ items.title }}</span>
        </template>
        <!-- 子菜单 -->
        <el-menu-item v-for="(item, i) in items.subMenu" :key="i" :index="item.navigatTo"
          @click="navigatToFunc(item)">{{
            item.subMenuTitle }}</el-menu-item>
      </el-sub-menu>
    </el-menu>
  </div>
</template>
<script>
import { toRefs, reactive, inject } from 'vue';
import { useRouter } from 'vue-router';
// import AppRouterClass from '../components/app/class/appRouterClass';
// 类

export default {
  name: '#nav',
  setup() {
    const router = useRouter();
    const storeDatas = inject('storeDatas'); // 所有共享数据
    const methods = reactive({
      /**
       * navigatToFunc 点击子菜单跳转
       * 流程：
       * 点击跳转且可返回的路由跳转，跳转过程中
       * 会执行appRouterClass.loginedNavigato函数中的router.beforeEach钩子
       * router.beforeEach执行时会设置tabs及nav菜单中高亮跳转的数据设置
       */
      navigatToFunc: (val) => {
        // 录入报关信息 提前登陆UUID
        if (val.navigatTo === '/createcustoms') {
          const isExist = Object.prototype.hasOwnProperty.call(storeDatas.tabsDataBus, val.navigatTo);
          // tabs存在要跳转的key，却不存在uuid数据
          if (!isExist || (isExist && !Object.prototype.hasOwnProperty.call(storeDatas.tabsDataBus[val.navigatTo].query, 'uuid'))) {
            // tabs中没有，说明没有点击过
            Object.assign(val.query, {
              uuid: `${new Date().valueOf()}${((Math.random() * 0x1000) | 0).toString().padStart(4, 0)}`,
            });
          } else {
            // tabs中有uuid数据
            Object.assign(val.query, {
              uuid: storeDatas.tabsDataBus[val.navigatTo].query.uuid,
            });
          }
        }
        // 点击跳转
        // push为可以返回路由
        router.push({
          path: val.navigatTo,
          query: { ...val.query }, // 参数(防止打开已设定好的uuid参数)
        }).catch((err) => {
          console.error(err);
        });
      },
    });
    return {
      storeDatas,
      ...toRefs(methods),
    };
  },
};
</script>
<style lang="scss" scoped>
.bigLogo,
.smallLogo {
  background-repeat: no-repeat;
  height: 60px;
  display: block;
  transition: all 0.5 ease-in-out;
  // border-bottom: 1px solid $gray;
  background-color: $logobgcolor;
}

.bigLogo {
  background-image: url('~@/assets/logo.svg');
}

.smallLogo {
  background-image: url('~@/assets/smallLogo.svg');
}

.el-menu {
  border-right: 0;
  background-color: $dark !important;
  --el-menu-item-hover-fill: rgb(44, 63, 65);

  .el-menu-item {
    text-align: left;
    padding-left: 52px !important;
  }

  .el-sub-menu {
    text-align: left;

    .el-menu-item {
      color: $hovefontcolor !important;
      background-color: $submenucolor !important;
      border-bottom: 1px solid $hovefontcolor;

      &:hover {
        background-color: $activebgcolor !important;
      }
    }

    .is-active {
      color: $activefontcolor !important;
      background: $activebgcolor !important;
    }
  }
}

//小图标
.el-menu--collapse {
  width: 70px;
  padding-top: 5px;
}
</style>
