<template>
  <div class="navbar text-white d-flex justify-content-between align-items-center">
    <div>
      <div class="text-white">
        <el-tooltip class="item" effect="light" :content="sideBarObj.zoomBtnToptitle" placement="bottom">
          <i @click="changeWidth" :class="sideBarObj.isShowNavList"></i>
        </el-tooltip>
      </div>
    </div>
    <div>
      <!-- 登陆 -->
      <div class="me-6 rtborder">
        <el-icon color="white" :size="20" class="d-flex justify-content-center align-items-cneter">
          <UserFilled />
        </el-icon>
        <div @click="loginDialogFunc" class="loginActive" v-if="!setFilterOfContainer.isLogin">登陆</div>
        <div @click="userinfoFunc" class="nickname" v-else>{{ cutUserName(setFilterOfContainer.userNickname) }}</div>
      </div>
      <teleport to="body">
        <div class="logindialogMain" v-show="setFilterOfContainer.showDialogOfLogin">
          <LoginDialog />
        </div>
      </teleport>
      <!-- 退出登陆 -->
      <div class="logoutDiv">
        <el-tooltip effect="light" content="退出" placement="bottom" popper-class="myPopover">
          <i @click="headerRouterClass.loginOutFunc" class="img-exit"></i>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
import {
  reactive, toRefs, inject,
} from 'vue';
import { useRouter } from 'vue-router';
// import { ElMessageBox, ElMessage } from 'element-plus';
import LoginDialog from './Login.vue';

// 类
import ChangeSideBarClass from '../components/nav/class/changSideBarClass';
import HeaderRouterClass from '../router/headerRouterClass';

export default {
  name: '#Header',
  components: {
    LoginDialog,
  },

  setup() {
    // 共享数据
    const sideBarObj = inject('sideBarObj'); // 申缩数据
    const setFilterOfContainer = inject('setFilterOfContainer'); // 存储向后台索取的数据
    const storeDatas = inject('storeDatas'); // 菜单栏
    const endTime = sessionStorage.getItem('endTime');

    const router = useRouter();

    const headerRouterClass = new HeaderRouterClass(router, setFilterOfContainer, storeDatas);

    // 路由守卫
    headerRouterClass.initBeforeEach();

    // 类: 设置侧边栏申缩样式
    const changeSideBarCls = new ChangeSideBarClass(sideBarObj);
    const methods = reactive({
      // 改变侧边栏宽度
      changeWidth: () => {
        changeSideBarCls.toggleSideBar();
      },
    });

    // loginDialogFunc 登陆提示
    function loginDialogFunc() {
      setFilterOfContainer.setFilter(0.5);
      setFilterOfContainer.loginRunLoadFunc(); // 登陆将显示登陆弹窗
    }

    // cutUserName 截取用户展示为138****8888方法
    function cutUserName(userName) {
      const u = userName.toString();
      const l = userName.toString().length;
      if (l === 11) {
        return `${u.slice(0, 3)}**${u.slice(l - 4, l)}`;
      }
      return userName;
    }

    // userinfoFunc 用户信息函数
    function userinfoFunc() {
      // 点击跳转
      router.replace({ path: '/editinfo' }).catch((err) => {
        console.error(err);
      }); // push为可以返回路由
    }
    return {
      endTime,
      storeDatas,
      sideBarObj,
      ...toRefs(methods),
      loginDialogFunc,
      userinfoFunc,
      // loginOutFunc,
      cutUserName,
      headerRouterClass,
      setFilterOfContainer,
    };
  },
};
</script>
<style lang="scss" scoped>
.navbar {
  height: 100%;

  div {
    display: flex;
    align-items: center;
  }

  i {
    width: 20px;
    height: 20px;
    margin: auto 0;
    line-height: 100%;
    background-size: 20px;
    background-repeat: no-repeat;
    align-items: center;
    background-color: transparent;
    display: block;
  }

  .img-navlogo_70 {
    background-image: url('~@/assets/imgs/daohanglogo_70.svg');
  }

  .img-navlogo_200 {
    background-image: url('~@/assets/imgs/daohanglogo_200.svg');
  }

  .img-exit {
    background-image: url('~@/assets/imgs/exit.png');
    cursor: pointer;
  }

  div {
    height: 100%;
  }

  .rtborder>div {
    opacity: 0.8;
    text-shadow: 1px 2px black;
    font-weight: 600;
    position: relative;
    cursor: pointer;

    &::after {
      content: '|';
      position: absolute;
      right: -18px;
      bottom: 0;
      transform: scale(0.3, 1.4);
    }
  }
}

.j-cardtitle {
  :deep(.el-breadcrumb) {
    color: white;
    line-height: 60px;

    .el-breadcrumb__item,
    span,
    i {
      color: white;
    }
  }
}

.loginActive {
  padding-left: 5px;
  font-size: 14px;
}

.nickname {
  padding-left: 8px;
  font-size: 14px;
}
</style>
