import axios from 'axios';
import {
  ElMessageBox,
  ElLoading,
} from 'element-plus';
import { host } from '../../configs/config.json';

export default function () {
  const initAxios = axios.create({
    baseURL: `${host}/v1`,
    timeout: 20000, // 过期时间
    withCredentials: true,
    crossDomain: true,
  });
  let eload = null;// loading mark title

  // 发送数据拦截器
  initAxios.interceptors.request.use((config) => {
    if (config.url === '/getpdf2image') {
      eload = null;
    } else {
      eload = ElLoading.service({
        // fullscreen: true,
        lock: true,
        text: '正在加载数据...',
        background: 'rgba(0, 0, 0, 0.5)',
      });
    }
    return config;
  }, (error) => {
    Promise.reject(error);
  });

  // 收取数据拦截器
  initAxios.interceptors.response.use(
    (res) => {
      if (eload !== null) eload.close();// close mark page
      if (res.status === 200 && res.data.code === 1003) { // 访问过于频繁
        ElMessageBox.alert(`${res.data.message}`, '警告!!!', {
          confirmButtonText: 'OK',
          callback() {
          },
        });
        return Promise.reject(res.data);
      }
      if (res.config.url === '/getpdf2image/pdf') { // load pdf URL
        return res;
      }
      if (res.status === 200 && res.data.code === 200) {
        // 数据这null或message存在
        return res.data;
      }
      if (res.status === 200 && res.data.code !== 200) {
        return Promise.reject(res.data);
      }
      return res;
    },
    (error) => {
      if (eload !== null) eload.close();
      return Promise.reject(error);
    },
  );

  return { initAxios };
}
