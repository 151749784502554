import { ElNotification, ElMessageBox, ElMessage } from 'element-plus';
import myAxios from '../../../utils/axios/index_v1';

export default class MbverifyCode {
  #t // 记录倒计时值

  constructor(datas, holdTimeoutOfSecond) {
    this.datas = datas;// 存放于setFilterOfContainer.registDatas值
    this.HoldTimeoutOfSecond = holdTimeoutOfSecond;// 过期时间（单位:秒)
    this.#t = null;// 记录
  }

  getTimeoutNode = () => this.#t

  // 设置手机验证码等待时间
  #holdTimeOut = (s) => {
    if (this.#t === null) {
      // let s = this.HoldTimeoutOfSecond;
      this.datas.mbInputText = `重新获取${s}秒`;
      this.#t = setInterval(() => {
        s -= 1;
        this.datas.mbInputText = `重新获取${s}秒`;
        if (s < 1) {
          clearInterval(this.#t);
          this.#t = null;
          this.datas.mbInputText = '获取验证码';
        }
      }, 1000);
    }
  }

  // 清除当前倒计时
  clearInterValFunc = () => {
    clearInterval(this.#t);
    this.#t = null;
    this.datas.mbInputText = '获取验证码';
  }

  /**
   * 服务向手机验证码请求
   * @param {*} mobile  手机号码
   * @param {Number} querytype 申请类型 创建手机验证码0,核实手机验证码1
   */
  #axiosServicefunc = (nodeRef, mobile) => new Promise((resolve, reject) => {
    const that = this;
    myAxios()
      .initAxios({
        method: 'POST',
        url: '/mobile_verifycode',
        Headers: { Accept: 'application/json; charset=utf-8' },
        data: {
          caller: 'regist',
          mobile,
        },
      }).then((res) => {
        if (res.code === 200) {
          resolve(res);
        }
      }).catch((err) => {
        // console.log(err);
        this.clearInterValFunc();
        let message = '';
        if (err.code === 5001) { // 验证码已有
          that.#holdTimeOut(Number(err.message));
          message = `重复操作，请${err.message}秒后再试`;
        } else if (err.code === 4002) { // 手机号已被注册提示'
          // 焦点定位到手机
          // message = `${err.message}`;
          ElMessageBox.alert(`${err.message}`, '警告', {
            confirmButtonText: 'OK',
            callback() {
              that.FocusNode(nodeRef, 0);
            },
          });
        } else {
          message = `${err.message}`;
          reject(err);
        }
        // 1003超限访问
        // 4002手机号已被注册提示
        if (![1003, 4002].includes(err.code)) { // 不是超限访问，前面提示过了
          ElNotification({
            title: '错误提示',
            message,
            type: 'error',
          });
        }
      });
  })

  // FocusNode定位至错误输入框
  FocusNode = (nodeRef, num) => {
    const nodeEl = nodeRef.$el[num];
    nodeEl.focus();// 获得焦点
    nodeEl.select();// 全选
  }

  // 手机获取方法
  HandelGetMbFunc = (registRef, mobile) => {
    // 这里异步执行;
    registRef.validateField('mobile', (message) => {
      if (!message && this.#t === null) {
        // 校验手机正确并且没有在执行setinterval
        // 校验手机格式
        this.#holdTimeOut(this.HoldTimeoutOfSecond);
        this.#axiosServicefunc(registRef, mobile).then(() => {
          // TODO
        }).catch(() => {
          // TODO
        });
      } else {
        ElMessage.error('手机填写有误');
      }
    });
  }
}
