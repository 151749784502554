import {
  ElLoading,
  ElMessage,
} from 'element-plus';

// 引入axios实例
import myAxios from '../../../utils/axios/index_v1';
import CreateCstmUtilClass from './createCstmUtilClass';
import MenuListClass from '../../../store/class/menuListClass';
import TabClkClass from '../../tabslist/class/tabClkClass';

export default class CreateCustomsClass extends CreateCstmUtilClass {
  #setTimeoutObj; // 记录timeOut

  #router;

  constructor(createCustomsDatasBus, setFilterOfContainer, storeDatas, router) {
    super(createCustomsDatasBus, setFilterOfContainer);
    this.handelName = ''; // 当前操作是提交还是暂存
    this.loadingInstance = null; // 加载提示
    this.submitDatas = ''; // 提交的暂存数据
    this.submitObj = 'submitObj'; // 常量：提交
    this.submitStringify = 'submitStringify'; // 常量：暂存
    this.getDataByUuid = 'getDataByUuid'; // 常量：获取operation数据
    this.getTradeByUuid = 'getTradeByUuid'; // 常量：获取trade数据
    this.copyTradeByUuid = 'copyTradeByUuid'; // 常量：复制trade数据
    this.setDataByUuid = 'setDataByUuid'; // 常量：rds写入sql
    this.storeDatas = storeDatas;
    this.#setTimeoutObj = {}; // 记录timeout,记录上次与的当前settimeout
    this.#router = router;
    this.tabClkClass = new TabClkClass(this.storeDatas, '', this.#router);
  }

  // setHandelName设置this.handelName的范围值
  setHandelName = () => {
    if (
      ![
        this.submitStringify,
        this.submitObj,
        this.getDataByUuid,
        this.getTradeByUuid,
        this.copyTradeByUuid,
        this.setDataByUuid,
      ].includes(this.handelName)
    ) {
      throw new Error('数据参数不正确');
    }
  };

  // saveBeforeOption 保存前操作
  saveBeforeOption = () => {
    //  判断当前进度值是否需要修改
    const { backupStepDatas, stepAllDatas } = this.createCustomsDatasBus;
    if (backupStepDatas.backupActive === null && stepAllDatas.active !== 0) {
      // 未在混装情况下，都归0
      stepAllDatas.active = 0;
    }
  };

  // storageDatasToService 暂存函数
  // 不保验证数据的正确性
  storageDatasToService = () => {
    // 暂存前表单中至少有一条数据
    if (this.createCustomsDatasBus.tableDatas.length === 0) {
      ElMessage({
        type: 'error',
        message: '暂存失败,暂存前请录入一条完整产品信息',
      });
      return;
    }
    try {
      const newDatas = {};
      // 保存前先赋值创建uuid
      const { uuid } = this.createCustomsDatasBus;
      uuid.value = uuid.value || `${new Date().valueOf()}${(Math.random() * 0x1000) | 0}`;
      this.saveBeforeOption(); // 判断当前进度值是否需要修改
      this.deepCopyDatas(this.createCustomsDatasBus, newDatas);

      this.handelName = this.submitStringify; // 标记当前类型为暂存
      // encodeURIComponent 解决收到后中文乱码问题
      const r = encodeURIComponent(JSON.stringify(newDatas));

      if (this.submitDatas !== r) {
        // 数据修改过
        this.submitDatas = r; // 保存数据
        this.axiosFunc(); // 向服务器提交数据
      } else {
        // 数据未修改过
        ElMessage({
          type: 'warning',
          message: '当前暂存内容未修改过!',
        });
      }
    } catch (error) {
      ElMessage({
        type: 'error',
        message: '暂存失败!',
      });
      console.error(error);
    }
  };

  // submitDatasFunc 提交函数
  submitDatasFunc = () => {
    if (this.createCustomsDatasBus.tableDatas.length === 0) {
      ElMessage({
        type: 'error',
        message: '提交失败,提交前请录入一条完整产品信息',
      });
      return;
    }
    this.handelName = this.submitObj; // 标记为提交
    try {
      if (this.isLoginUser()) {
        // 提交前先登陆
        return;
      }
      const p = this.handelVerifyResult();// 检测整个数据是否填写完整
      p.then((bl) => {
        // 每栏 项目 校验结果无误且已登陆
        if (!bl) {
          // true：有误，false：无误
          this.saveBeforeOption(); // 判断当前进度值是否需要修改
          // 无误后提交数据至服务器
          this.submitDataBefore(); // 先序列化字符串数据至submitDatas中
          this.axiosFunc(); // 提交数据
        }
      }).catch((err) => {
        console.error(err);
      });
    } catch (error) {
      console.error(error);
    } finally {
      // 关闭加载
      this.loadingInstanceClose(); // 关闭加载提示信息
    }
  };

  /**
   * 加载createcustoms页面向后台加载数据
   * 需要判断params参数值内容
   * 分新创建和从点击编辑按钮出来过来编辑数据
   */
  queryDataFromRouter = () => {
    const queryObj = JSON.parse(
      decodeURIComponent(window.atob(this.#router.currentRoute.value.query.params)),
    );
    if (queryObj.uuid) {
      // 默认rds,tempSubmit为0，指示服务器getDataByUuid
      let queryType = this.getDataByUuid;
      const { uuid, cuuid } = queryObj; // uuid：创建订单UUID，cuuid：查询数据库uuid(复制)
      let queryUUID = uuid; // 存放向数据查询的uuid

      // 从路由传递过来的数据db参数为1时查询trade数据
      if (Object.prototype.hasOwnProperty.call(queryObj, 'db')) {
        switch (queryObj.db) {
          case '0': // 状态：未登陆|rds|只能暂存任务|存于rds|编辑操作
            queryType = this.getDataByUuid;
            break;
          case '1': // 状态：登陆|tempSubmit|暂存任务|存于sql.operation|编辑操作
            queryType = this.getDataByUuid;
            break;
          case '2': // 状态：登陆|submit|提交任务|存于sql.trade|编辑操作
            queryType = this.getTradeByUuid;
            break;
          case '3': // 状态：登陆|copy|查询任务|拉取存于sql.trade|复制操作
            queryType = this.copyTradeByUuid;
            queryUUID = cuuid || ''; // 初始加载数据库的uuid
            break;
          default: // 状态：其他
            queryType = this.getDataByUuid;
            break;
        }
      }

      // 设置向服务器加载展示数据的uuid
      this.createCustomsDatasBus.uuid.value = queryUUID;

      // 根据uuid向服务拉取展示数据
      this.QueryDataStateByUuid(queryType);

      // 设置最终提交|暂存数据的uuid
      this.createCustomsDatasBus.uuid.value = uuid;
    }
  };

  // 返回按钮
  goBackFunc = () => {
    // 关闭当前页
    this.tabClkClass.tabDatas = this.storeDatas.tabsDataBus['/createcustoms'];
    this.tabClkClass.closeTabFunc(true, '/customsdocs');
    // 跳转至录入报关信息页
    this.#router.replace({
      path: 'customsdocs',
    }).catch((err) => {
      ElMessage.log(err);
    });
  };

  /*
   * 根据uuid获取数据
   * @param {*} queryType 页面加载时请求类型（默认："getDataByUuid")
   */
  QueryDataStateByUuid = (queryType) => {
    this.handelName = queryType || this.getDataByUuid; // 标记为获取数据
    this.submitDatas = ''; // 没有提交的数据，这里只查询，故为空
    this.axiosFunc(); // 向服务器查询数据
  };

  /** isLoginUser 判断是否登陆用户
   * @returns true:未登陆用户 false：已登陆过
   */
  isLoginUser = () => {
    // 先判断是否登陆
    if (!this.setFilterOfContainer.isLogin) {
      // 非登陆用户
      this.setFilterOfContainer.showLoginDialog();

      return true;
    }
    return false;
  };

  // submitDataBefore 提交前的数据处理
  submitDataBefore = () => {
    this.handelName = this.submitObj; // 设置为『提交』类型
    const newDatas = {};
    const {
      stepAllDatas, tableDatas, tradeInfoDatas, factoryInfodatas, consigneeInfoDatas, exportnameInfodatas,
    } = this.createCustomsDatasBus;
    newDatas.stepActive = stepAllDatas.active; // 当前进度
    newDatas.tableDatas = tableDatas; // 产品信息
    newDatas.tradeDatas = tradeInfoDatas.tradeDatas; // 贸易信息
    newDatas.exportnameData = exportnameInfodatas.exportnameData; // 经营单位基本信息
    newDatas.manufacturer = factoryInfodatas.manufacturer; // 生产企业基本信息
    newDatas.consneDatas = consigneeInfoDatas.consneDatas; // 境外收货人信息
    // 设置提交的数据字符化
    this.submitDatas = encodeURIComponent(JSON.stringify(newDatas));
  };

  // productInfoVfyFunc校验 产品信息栏
  productInfoVfyFunc = () => {
    // this.loadingInstanceFunc('正在校验 产品信息栏');
    const {
      config, // 产品信息栏编辑状态
      tableDatas, // 表单数据
    } = this.createCustomsDatasBus;
    // 判断是否有录入信息
    if (config.currentBtnState !== 'tableStateShow') {
      return '正处于编辑状态';
    }
    if (Array.isArray(tableDatas) && tableDatas.length === 0) {
      return '请先添加数据';
    }
    return true;
  };

  // tradeInfoVfyFunc校验 贸易信息栏
  tradeInfoVfyFunc = () => new Promise((resolve) => {
    // this.loadingInstanceFunc('正在校验 贸易信息栏');
    const { tradeInfoFormRef } = this.createCustomsDatasBus;
    tradeInfoFormRef.value.validate((e) => {
      // 返回false有误，true：无误
      resolve(e);
    });
  });

  // exportnameInfoVfyFunc校验 经营单位基本信息
  exportnameInfoVfyFunc = () => new Promise((resolve) => {
    // this.loadingInstanceFunc('正在校验 生产企业基本信息');
    const { exportnameInfoRef } = this.createCustomsDatasBus;
    exportnameInfoRef.value.validate((e) => {
      // 返回false有误，true：无误
      resolve(e);
    });
  });

  // manufacturerInfoVfyFunc校验 生产企业基本信息
  manufacturerInfoVfyFunc = () => new Promise((resolve) => {
    // this.loadingInstanceFunc('正在校验 生产企业基本信息');
    const { factoryInfoRef } = this.createCustomsDatasBus;
    factoryInfoRef.value.validate((e) => {
      // 返回false有误，true：无误
      resolve(e);
    });
  });

  // consigneeInfoVfyFunc校验 境外收货人信息
  consigneeInfoVfyFunc = () => new Promise((resolve) => {
    // this.loadingInstanceFunc('正在校验 境外收货人信息');
    const { consigneeInfoRef } = this.createCustomsDatasBus;
    consigneeInfoRef.value.validate((e) => {
      // 返回false有误，true：无误
      resolve(e);
    });
  });

  // 根据funcKeys调用对应函数
  // 注意：funckeys关联到函数名及class名称，不要随意改变
  // promise true：有误，false：无误
  handelVerifyResult = async () => {
    const { elMainRef } = this.createCustomsDatasBus;
    const funcKeys = ['productInfo', 'tradeInfo', 'exportnameInfo', 'manufacturerInfo', 'consigneeInfo'];
    const cnKeys = ['产品信息栏', '贸易信息栏', '生产企业基本信息', '境外收货人信息'];
    const promiseObj = {};
    funcKeys.forEach((item) => {
      const res = this[`${item}VfyFunc`]();
      promiseObj[item] = res;
    });
    let bl = false; // 记录是否有误（true：有误，false：无误）
    // 全部校验正确性(不为true的值均为有误 )
    await Promise.all(Object.values(promiseObj))
      .then((res) => {
        const el = elMainRef.value.$el.children[0].children[0];

        res.forEach((item, idx) => {
          // 提示信息
          this.loadingInstanceFunc(`正在校验 ${cnKeys[idx]}`);
          if (item !== true) {
            // 有误
            // 设置定位信息
            const subElement = el.children[idx + 1];
            el.scrollTop = subElement.offsetTop;
            let className = Object.keys(promiseObj)[idx];
            if (Object.keys(promiseObj)[idx] === 'productInfo') {
              // 清除样式，方便下次添加
              className = res === '请先添加数据' ? '.weightTable' : '.weightInfo';
              this.changeClassFunc(subElement, className);
              this.loadingInstanceClose(); // 关闭加载提示信息
              ElMessage({
                type: 'error',
                message: `${cnKeys[idx]} ${item}`,
              });
              if (bl === false) bl = true;
              throw item;
            }

            this.changeClassFunc(subElement, `.${className}`);
            this.loadingInstanceClose(); // 关闭加载提示信息
            ElMessage({
              type: 'error',
              message: `${cnKeys[idx]} 有误`,
            });
            if (bl === false) bl = true;
            throw item;
          }
          this.loadingInstanceClose(); // 关闭加载提示信息
        });
      })
      .catch(() => {
        this.loadingInstanceClose(); // 关闭加载提示信息
      });
    return bl;
  };

  // changeClassFunc 闪烁红色边框
  // 原理：当元素subElement有误，则定位至错误位置，高亮红色边框
  // 同时操作前清除上次的className，
  changeClassFunc = (subElement, className) => {
    try {
      //  清除上次样
      if (Object.prototype.hasOwnProperty.call(this.#setTimeoutObj, className)) {
        clearTimeout(this.#setTimeoutObj[className]);
        subElement
          .querySelector(this.#setTimeoutObj.pervClassName)
          .setAttribute('class', this.#setTimeoutObj.pervClassName.replace('.', ''));
      }
      //  闪烁红色边框 产品信息栏 需要细分录入栏 还是 表单栏
      subElement
        .querySelector(className)
        .setAttribute('class', `${className.replace('.', '')} animal`);

      this.#setTimeoutObj.pervClassName = className;
      this.#setTimeoutObj[className] = setTimeout(() => {
        subElement.querySelector(className).setAttribute('class', className.replace('.', ''));
        delete this.#setTimeoutObj[className];
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  // loadingInstanceFunc 加载提示
  loadingInstanceFunc = (text) => {
    if (this.loadingInstance === null) {
      this.loadingInstance = ElLoading.service({
        // text: `正在${this.handelName}【${this.labelObj[text]}】数据`,
        text,
        background: 'rgba(0,0,0,0.3)',
      });
    }
  };

  // loadingInstanceClose 关闭
  loadingInstanceClose = () => {
    if (this.loadingInstance !== null) {
      this.loadingInstance.close();
      this.loadingInstance = null;
    }
  };

  /**
   * createcustoms页页向后台服务器异步获取数据
   * @returns 从台后返回的数据值（base64结果值)
   */
  axiosFunc() {
    try {
      // 先校验提交值类型是否符合
      this.setHandelName(this.handelName);
    } catch (error) {
      console.error('参数错误');
      return;
    }
    myAxios()
      .initAxios({
        method: 'POST',
        url: '/create_customs',
        headers: { 'Content-Type': 'application/json' },
        data: {
          productUuid: this.createCustomsDatasBus.uuid.value, // 产品编辑id唯一识别号
          productModelType: this.handelName, // 操作类型用于后台判别操作函数
          productData: this.submitDatas, // 向后台传递数据
        },
      })
      .then((res) => {
        // 清空数组中内容
        const f = function (ary) {
          ary.pop();
          if (ary.length !== 0) {
            f(ary);
          }
        };

        const { tableDatas } = this.createCustomsDatasBus;
        if (res.code === 200) {
          if (
            [this.submitObj, this.submitStringify, this.setDataByUuid].includes(this.handelName)
          ) {
            // 提交、暂存、重新更新后标记"查询"文件重新加载数据
            this.setFilterOfContainer.loadCustomsdocsState = true;
          }

          if ([this.getDataByUuid].includes(this.handelName)) { // 暂存
            // 依据uuid查询数据
            // decodeURIComponent 解决中文乱码问题
            const v = JSON.parse(decodeURIComponent(window.atob(res.result)), null, 3);
            // 清空产品信息栏中的表单数据(递归清空)
            // 删除ID

            f(tableDatas);

            // 解决响应式问题 , 从数据库查询的数据复原（响应式）
            this.deepAssignData(this.createCustomsDatasBus, v);
            // 初始化产品信息栏中的表格中样式
            this.initDetailTableStyle(this.getDataByUuid);
            // 向查询数所customsDocsDataBus
          } else if (this.handelName === this.setDataByUuid) {

            // 持久化保存之前未登陆时暂存的数据
          } else if (this.handelName === this.submitObj) { // 提交数据
            const { stepAllDatas } = this.createCustomsDatasBus;
            stepAllDatas.active = res.stepState; // 设置当前进度值

            // 跳转路径
            this.tabClkClass.tabDatas = this.storeDatas.tabsDataBus['/createcustoms'];
            this.tabClkClass.closeTabFunc(true, '/customsdocs');
          } else if ([this.getTradeByUuid, this.copyTradeByUuid].includes(this.handelName)) { // 通过录入报关信息栏进入查询
            if (res.result !== null && res.result !== '') {
              const {
                consigneeInfoDatas, factoryInfodatas, stepAllDatas, tradeInfoDatas, exportnameInfodatas,
              } = this.createCustomsDatasBus;
              // 解析成JSON对象
              const v = JSON.parse(decodeURIComponent(window.atob(res.result)), null, 3);
              // 清空产品指定表单数据(递归清空)
              f(tableDatas);
              // 赋值
              v.tableDatas.map((item) => {
                item.productName = `${item.productName}  ${item.declare_element}`;// 将申报内容与产品名称合并
                delete item.declare_element;// 删除申报内容
                delete item.id; // 删除id标签,避免后期提交服务器报错。
                return item;
              });
              tableDatas.unshift(...v.tableDatas);
              Object.assign(consigneeInfoDatas.consneDatas, v.consneDatas);// 赋值给 收货
              Object.assign(factoryInfodatas.manufacturer, v.manufacturer);// 赋值给 生产单位
              Object.assign(tradeInfoDatas.tradeDatas, v.tradeDatas);// 赋值给 贸易方式
              Object.assign(exportnameInfodatas.exportnameData, v.exportNameData);// 赋值给 经营单位
              stepAllDatas.active = v.stepActive;

              // 初始化产品信息栏中的表格中样式
              this.initDetailTableStyle(this.getTradeByUuid);
            }
          } else {
            ElMessage({
              type: 'success',
              message:
                res.message === 'Okay'
                  ? `${this.handelName === this.submitStringify ? '暂存成功' : '提交成功'}`
                  : res.message,
            });
          }
        }
        // 要求以下页面展示时需要重新加载数据
        Object.assign(this.setFilterOfContainer, {
          loadCustomsdocsState: true, // 制作报关文件查询文件重新加载数据
          loadOnPayLineState: true, // 在线支付重新加载数据
          loadHomeStatistics: true, // 首页重新加载数据
        });
      })
      .catch((err) => {
        if (err.code === 1009) {
          // 需要登陆
          this.setFilterOfContainer.showLoginDialog(); // 展示登陆框
          const menuListClass = new MenuListClass(
            this.storeDatas,
            this.setFilterOfContainer,
            this.#router,
          );
          menuListClass.setElementInfo(err); // 提示登陆信息
        } else if ([2001].includes(err.code)) {
          // 数据重复或不可编辑
          ElMessage({
            type: 'error',
            message: `${err.message},准备跳转"查询制作文件"`,
            duration: 5000,
          });
          setTimeout(() => {
            this.tabClkClass.tabDatas = this.storeDatas.tabsDataBus['/createcustoms'];
            this.tabClkClass.closeTabFunc(true, '/customsdocs');
          }, 2000);
        } else if ([1404, 1003].includes(err.code)) {
          // // 1003 访问过于频繁 1404 未找到
          // // 没有当前UUID数据
          if ([this.getTradeByUuid].includes(this.handelName)) {
            ElMessage({
              type: 'error',
              message: err.message,
            });
          }
        } else {
          ElMessage({
            type: 'error',
            message: err.message,
          });
        }
      });
  }
}
