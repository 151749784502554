import { createRouter, createWebHistory } from 'vue-router';
import ConstClass from '../store/class/constClass';

const routes = [
  { // 我的工作台主页
    path: '/',
    name: 'Home',
    meta: {
      title: '捷汇锐|我的工作台',
      description: '专业为出口企业量身打造集在线制作出口报关单证、海关编码查询的自动化系统，实现出口业务的高效、便捷、安全。通过自动化生成单证，减少人为错误，实时更新数据，使操作更加准确迅速，也为企业提供了强有力的技术支撑。本系统主要为出口企业提供在线制作出口装箱单、商业发票、合同、报关单。让出口业务更加简单、便捷、安全。',
      keywords: '出口报关系统|海关编码查询|录制报关单系统|海关商品编码查询|出口外贸单证系统|在线制作出口报关资料|海关hs编码查询|出口装箱单发票合同|出口报关单填写',
      visitAuth: ConstClass.PAGE_UNLOGIN_USER, // 允许用户访问权限
    },
    component: () => import('@/views/Home.vue'),
  },
  { // 制作报关文件查询页
    path: '/customsdocs',
    name: 'Customsdocs',
    meta: {
      title: '捷汇锐|查询',
      description: '查询录制成功的报关资料、下载与查看、编辑等内容',
      keywords: '录单报关系统|出口报关单证查询系统|出口外贸单证系统',
      visitAuth: ConstClass.PAGE_UNLOGIN_USER,
    },
    component: () => import('@/views/Customsdocs.vue'),
  },
  { // 录入报关信息
    path: '/createcustoms',
    name: 'Createcustoms',
    meta: {
      title: '捷汇锐|录入报关信息',
      description: '在线制作出口报关资料、海关编码数据查询、一健系统自动化生成出口报关资料、装箱单、发票、合同、出口报关单全套出口报关资料',
      keywords: '海关编码查询系统|出口装箱单系统|出口发票系统|出口合同系统|出口报关单系统',
      visitAuth: ConstClass.PAGE_UNLOGIN_USER,
    },
    component: () => import('@/views/Createcustoms.vue'),
  },
  { // 审核页（要有一定权限才能查看）
    path: '/audit',
    name: 'Audit',
    meta: {
      title: '捷汇锐|审核',
      description: '管理员审核用户提交在线录制报关资料',
      keywords: '审核报关资料系统',
      visitAuth: ConstClass.PAGE_ADMIN,
    },
    component: () => import('@/views/Audit.vue'),
  },
  { // 账务明细
    path: '/accountingdetail',
    name: 'Accountingdetail',
    meta: {
      title: '捷汇锐|账务明细',
      description: '用户查看账户明细，查看账户余额',
      keywords: '账户明细',
      visitAuth: ConstClass.PAGE_UNLOGIN_USER,
    },
    component: () => import('@/views/Accountingdetail.vue'),
  },
  { // 在线支付
    path: '/onlinepay',
    name: 'Onlinepay',
    meta: {
      title: '捷汇锐|在线支付',
      description: '当前数据查询结果包含"审核通过","待委托授权"、"已支付"数据',
      keywords: '查询在线支付',
      visitAuth: ConstClass.PAGE_UNLOGIN_USER, // 用户登陆后才能支付
    },
    component: () => import('@/views/Onlinepay.vue'),
  },
  { // 账户安全(找回密码)
    path: '/usersecurity',
    name: 'Usersecurity',
    meta: {
      title: '捷汇锐|账户安全',
      description: '账户安全',
      keywords: '账户安全',
      visitAuth: ConstClass.PAGE_ONLY_UNLOGIN_USER,
    },
    component: () => import('@/views/Usersecurity.vue'),
  },
  { // 账户安全(修改密码)
    path: '/updatepwd',
    name: 'Updatepwd',
    meta: {
      title: '修改密码',
      description: '找回密码',
      keywords: '找回密码',
      visitAuth: ConstClass.PAGE_LOGIN_USER,
    },
    component: () => import('@/views/Updatepwd.vue'),
  },
  { // 资料修改
    path: '/editinfo',
    name: 'Editinfo',
    meta: {
      title: '资料修改',
      description: '修改用户资料，公司信息',
      keywords: '添加用户昵称|修改公司信息',
      visitAuth: ConstClass.PAGE_LOGIN_USER,
    },
    component: () => import('@/views/Editinfo.vue'),
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
