import { inject } from 'vue';

export default function () {
  const tabsDataBus = inject('tabsDataBus');
  const keepAliveLists = inject('keepAliveLists');
  const currentShowPageBus = inject('currentShowPageBus');

  /** clearCache 清除参数数组中页缓存
   *
   * @param {array} willDelAryKey 要删除的数组
   */
  const clearCache = (willDelAryKey) => {
    // 注意：这里key值是固定的
    Object.keys(keepAliveLists).forEach((key) => {
      // 因为key为去除"/"前缀数据
      if (Array.isArray(willDelAryKey) && willDelAryKey.includes(`/${keepAliveLists[key]}`)) {
        delete keepAliveLists[key];
      }
    });
  };

  /**
     * down下拉关闭集合事件
     * @param {*} state 当前vuex中数据
     * @param {*} options 对象：{currentShowPage:'当前展示页 , method:'需要关闭类型'}
     */
  const downCloseMethods = (options) => {
    const { currentShowPage, method } = options;
    const tabsAry = Object.keys(tabsDataBus); // tabs下标数组
    const showIndex = tabsAry.findIndex((item) => item === currentShowPage);// 当前元素在tabs数组中的位置
    // showIndex在'/'会返回-1
    if (showIndex === -1) {
      return;
    }
    let delAry = [];

    switch (method) {
      case 'el-icon-back':// 关闭左侧
        delAry = tabsAry.splice(1, showIndex - 1);
        for (let i = 0; i < delAry.length; i += 1) {
          delete tabsDataBus[delAry[i]];
        }
        clearCache(delAry);
        break;
      case 'el-icon-right':// 关闭右侧
        delAry = tabsAry.splice(showIndex + 1, tabsAry.length);
        for (let i = 0; i < delAry.length; i += 1) {
          delete tabsDataBus[delAry[i]];
        }
        clearCache(delAry);
        break;
      case 'el-icon-close':// 关闭其他
        delAry = tabsAry.splice(showIndex + 1, tabsAry.length);
        delAry.push(...tabsAry.splice(1, showIndex - 1));

        for (let i = 0; i < delAry.length; i += 1) {
          delete tabsDataBus[delAry[i]];
        }
        clearCache(delAry);
        break;
      default:// 关闭所有
        for (let i = 1; i < tabsAry.length; i += 1) {
          delete tabsDataBus[tabsAry[i]];
        }
        clearCache(tabsAry);
        tabsDataBus['/'].active = 1;
        currentShowPageBus.name = '/';
    }
  };

  return {
    clearCache,
    downCloseMethods,
  };
}
